<template>
  <v-app v-resize="onResize" class="primary-keep sdbar" :class="[$store.state.screenWidth <= 959 ? 'mobile-view' : '']">
    <Auth v-if="!$store.state.user" />
    <v-snackbar
      v-model="snackbarElShow"
      :centered="$store.state.snackbarCentered"
      :top="!$store.state.snackbarCentered"
      :multi-line="true"
      dark
      color="black"
    >
      {{ $store.state.snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          icon
          small
          v-bind="attrs"
          @click="snackbarElShow = false"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      v-if="$store.state.user"
      v-model="drawer"
      app
      dark
      class="no-print"
    >
      <v-list-item class="px-0 pt-0 secondary-keep sdbar">
        <v-list-item-content class="justify-end sidebar-head py-0">
          <v-list-item-title class="d-flex align-center justify-center secondary-keep sdbar mb-0 pt-4 pb-3">
            <img src="/images/new/logo-h.png" class="d-block" height="36px">
          </v-list-item-title>
          <v-list-item-subtitle class="d-flex flex-column justify-end text-right text-capitalize primary-keep sdbar py-4">
            <div class="d-flex align-center justify-center">
              <v-avatar size="69.78" class="grey lighten-2">
                <img
                  alt="user"
                  class="pa-1 rounded-circle"
                  :src="'/icon.png'"
                >
              </v-avatar>
            </div>
            <div class="font-weight-bold text-center pt-3 white--text tt-default" style="font-size: 12px;">
              <span class="text-capitalize">{{ $store.state.user.uid_name || 'master' }}</span> - {{ $store.state.user.name }}
              <sup v-if="parseInt($store.state.user.uid)" class="who-am-i text-capitalize">
                {{ $store.state.user.role }}
              </sup>
            </div>
            <div class="d-flex pt-1 align-center justify-center caption-small text-center pt-1 pb-1 white--text" style="padding-top: 2px;">
              <div class="d-flex align-center px-1">
                <v-icon x-small :color="parseInt($store.state.user.status) ? 'green' : 'red'" class="mr-1" style="font-size: 8px;margin-bottom: 2px;">
                  mdi-circle
                </v-icon>
                {{ parseInt($store.state.user.status) ? 'Active' : 'Not Active' }}
              </div>
              <div class="d-flex align-center">
                <v-chip v-if="$store.state.user.role === 'agen' || $store.state.user.role === 'distributor'" x-small color="primary" dark class="px-1 d-flex align-center caption-small">
                  <v-icon class="mr-1 orange--text font-weight-medium" style="font-size: 12px;">
                    mdi-star
                  </v-icon>
                  {{ $formatMoney(parseInt($store.state.poin_limit), 0, '.', ',') }} {{ parseInt($store.state.poin_limit) > 1 ? 'Points' : 'Point' }}
                </v-chip>
              </div>
            </div>
            <div class="caption-small d-flex align-center text-center justify-center" style="padding-top: 2px;">
              <span class="d-inline-flex pt-1">
                Light
              </span>
              <v-switch
                v-model="toggleTheme"
                outlined
                color="white"
                class="d-inline-flex mt-0 mx-2"
                hide-details
              />
              <span class="d-inline-flex pt-1">
                Dark
              </span>
            </div>
            <div class="pt-3 px-6">
              <v-btn block depressed color="red darken-2" small class="caption rounded-xl text-capitalize" @click.prevent="doLogout()">
                Logout
              </v-btn>
            </div>
          </v-list-item-subtitle>
          <!-- <v-spacer /> -->
          <!-- <div class="d-flex">
            <div class="pt-6 pl-5" :class="[parseInt($store.state.user.status) ? 'primary--text' : 'grey--text']">
              {{ parseInt($store.state.user.status) ? 'Active' : 'Not Active' }}
            </div>
            <v-spacer />
          </div> -->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list
        dense
        nav
      >
        <template v-for="item in $store.state.user.role === 'agen' ? menusAgen : menus">
          <v-list-item
            v-if="$roles($store.state.user.uid_roles, item.slug)"
            :key="item.title"
            link
            class="py-1"
            :to="item.slug"
          >
            <v-list-item-icon class="mr-2">
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span>
                  {{ item.title }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      v-if="$store.state.user"
      app
      mobile-breakpoint="767"
      class="secondary-keep sdbar pr-6"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title>
        <!-- <v-text-field
          outlined
          dense
          hide-details
          color="white"
          placeholder="Search"
          autocomplete="off"
          class="d-block rounded-xl h-small"
        /> -->
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="$route.name === 'Transaction'"
        icon
        small
        class="mr-4"
        color="orange darken-2"
        @click.prevent="$store.commit('DIALOG_SHOPEE', true)"
      >
        <v-icon>
          mdi-store
        </v-icon>
      </v-btn>
      <v-menu
        v-model="menu"
        :nudge-bottom="18"
        offset-y
        max-width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="red"
            :value="unreadLength()"
            :content="unreadLength()"
            overlap
          >
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on">
              <v-icon>
                mdi-bell-outline
              </v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-card light style="max-height: calc(100vh - 50px);overflow-y: auto;">
          <v-list v-if="$store.state.notifications.length">
            <div v-for="(notif, iNotif) in $store.state.notifications" :key="'notification-' + iNotif">
              <v-list-item
                class="d-flex"
                :class="[notif.read ? 'read' : 'unread']"
                @click.prevent="GoToNotif(notif)"
              >
                <v-list-item-avatar height="35" width="35">
                  <img
                    :src="notif.img || '/icon.png'"
                    alt="Image"
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="caption-small font-weight-normal">
                    <strong>{{ (notif.creator_name) }}</strong>
                    {{ NotifText(notif) }}
                    <div class="pt-1">
                      {{ $localDT(notif.created, 'display') }}
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-1" />
            </div>
          </v-list>
        </v-card>
      </v-menu>
      <!-- <v-switch
        v-model="toggleTheme"
        label="Dark"
        color="primary"
        hide-details
      ></v-switch> -->
    </v-app-bar>
    <v-main v-if="$store.state.user">
      <router-view />
    </v-main>
    <Popup />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: (vm) => ({
    snackbarElShow: false,
    menu: false,
    drawer: false,
    menus: [
      {
        title: 'Overview',
        icon: 'mdi-chart-areaspline',
        slug: '/'
      },
      {
        title: 'Transaction',
        icon: 'mdi-account-cash',
        slug: '/transaction'
      },
      {
        title: 'Product & Inventory',
        icon: 'mdi-barcode',
        slug: '/product',
        hideWhenNotActive: true
      },
      {
        title: 'Packaging Manager',
        icon: 'mdi-gift-outline',
        slug: '/packaging',
        hideWhenNotActive: true
      },
      {
        title: 'Finance',
        icon: 'mdi-cash',
        slug: '/finance'
      },
      /* {
        title: 'Collaboration',
        icon: 'mdi-account-group-outline',
        slug: '/collaboration'
      }, */
      {
        title: 'Point & Rewards',
        icon: 'mdi-medal-outline',
        slug: '/reward',
        hideWhenNotActive: true
      },
      /* {
        title: 'Forum',
        icon: 'mdi-forum-outline',
        slug: '/knowledge-base',
        hideWhenNotActive: true
      },
      {
        title: 'Module Learning',
        icon: 'mdi-school',
        slug: '/module-learning',
        hideWhenNotActive: true
      }, */
      {
        title: 'User',
        icon: 'mdi-account-multiple',
        slug: '/user',
        hideWhenNotActive: true
      },
      {
        title: 'Biolink',
        icon: 'mdi-vector-link',
        slug: '/biolink',
        hideWhenNotActive: true
      },
      {
        title: 'Account Setting',
        icon: 'mdi-cog',
        slug: '/setting'
      }
    ],
    menusAgen: [
      {
        title: 'Overview',
        icon: 'mdi-chart-areaspline',
        slug: '/'
      },
      {
        title: 'Point & Rewards',
        icon: 'mdi-medal-outline',
        slug: '/reward',
        hideWhenNotActive: true
      },
      {
        title: 'Account Setting',
        icon: 'mdi-cog',
        slug: '/setting'
      }
    ],
    toggleTheme: false
  }),
  computed: mapState({
    snackbarShow: state => state.snackbarShow
  }),
  watch: {
    menu: function (v) {
      if (v) {
        this.$store.dispatch('NOTIFY_GET')
        localStorage.setItem('read-notif', this.$store.state.notifications.filter(r => !r.read).length)
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        this.$store.commit('DIALOG_SHOPEE', false)
        if (!from) {
          this.$store.dispatch('ME')
        }
      }
    },
    snackbarShow: function (v) {
      this.snackbarElShow = v
    },
    snackbarElShow: function (v) {
      if (!v) {
        this.$store.commit('SNACKBAR', {
          show: false,
          text: ''
        })
      }
    },
    toggleTheme: function (v) {
      this.$vuetify.theme.dark = v
      if (v) {
        localStorage.setItem('theme', '1')
      } else {
        localStorage.removeItem('theme')
      }
    }
  },
  created () {
    // this.$router.beforeEach((to, from, next) => {
    //   if (!this.$store.state.user && to.name !== 'Home') {
    //     this.$router.replace('/')
    //   } else {
    //     next()
    //   }
    // })
    // localStorage.setItem('theme', '1') // temp
    const dark = localStorage.getItem('theme')
    if (dark) {
      this.toggleTheme = true
    } else {
      this.toggleTheme = false
    }
    if (screen.width > 767) {
      this.drawer = true
    }
  },
  mounted () {
    this.updateMenus()
    this.$watch(() => this.$store.state.user.role, this.updateMenus)
    this.onResize()
    // this.$store.dispatch('POPUP', {
    //   t: this,
    //   show: true,
    //   type: '',
    //   title: 'Please Confirm',
    //   text: 'Apakah Kamu yakin ?'
    // }).then((res) => {
    //   console.log(res)
    // })
    // console.log('[store state user]', this.$store.state.user)
  },
  methods: {
    unreadLength () {
      let counter = 0
      const ls = localStorage.getItem('read-notif')
      const l = this.$store.state.notifications.filter(r => !r.read).length
      if (parseInt(ls)) {
        counter = (l - ls) > 0 ? (l - ls) : 0
      } else {
        counter = l
      }
      return counter
    },
    NotifText (notif) {
      if (notif.content_type === 'forum_create') {
        return 'created a new post'
      } else if (notif.content_type === 'forum_comment') {
        return 'commented on your post'
      } else if (notif.content_type === 'learning') {
        return 'created a new module learning'
      } else if (notif.content_type === 'transaction') {
        return 'created a new transaction'
      }
    },
    GoToNotif (notif) {
      this.$store.dispatch('NOTIFY_READ', Object.assign({}, notif))
      // readonly content_type: string; // forum_create, forum_comment, learning, transaction, birthday, product
      if (notif.content_type === 'forum_create' || notif.content_type === 'forum_comment') {
        this.$router.push('/knowledge-base')
      } else if (notif.content_type === 'learning') {
        this.$router.push('/module-learning')
      } else if (notif.content_type === 'transaction') {
        this.$router.push('/transaction')
      }
    },
    doLogout () {
      const admin = this.$store.state.user ? this.$store.state.user.uid_email : null
      this.$store.dispatch('POPUP', {
        t: this,
        show: true,
        type: 'confirm',
        title: 'Confirm',
        text: 'Are you sure want to logout?'
      }).then((res) => {
        if (res.result) {
          setTimeout(() => {
            if (admin) {
              this.$router.replace('/?admin=' + (admin || ''))
            } else {
              this.$router.replace('/')
            }
            this.$store.dispatch('DO_LOGOUT')
          }, 500)
        }
      })
    },
    onResize () {
      this.$store.commit('RESIZE', { x: screen.width, y: screen.height })
    },
    updateMenus () {
      if (this.$store.state.user.role === 'agen') {
        this.menus = [
          {
            title: 'Overview',
            icon: 'mdi-chart-areaspline',
            slug: '/'
          },
          {
            title: 'Point & Rewards',
            icon: 'mdi-medal-outline',
            slug: '/reward',
            hideWhenNotActive: true
          },
          {
            title: 'Account Setting',
            icon: 'mdi-cog',
            slug: '/setting'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
html,
body {
  background: #000;
}
.v-application {
  .theme--light {
    .v-application--wrap {
      background-color: #fafafa;
    }
  }
}
.v-navigation-drawer {
  background-color: #2D3343!important;
  bottom: 0%!important;
  height: 100%!important;
  box-shadow: 0px 0px 10px 5px rgba(68, 74, 92, 0.2);
  >.v-navigation-drawer__border {
    display: none;
  }
  .v-list--nav {
    background: #3A3F52!important;
  }
  .v-list-item--active {
    background-color: #2D3343!important;
    &:hover {
      background-color: #2D3343!important;
    }
    &::before {
      opacity: 0!important;
    }
  }
  .v-list-item {
    span {
      font-size: 12px;
    }
  }
}
.bg-none {
  background-color: transparent!important;
}
.shadow-none {
  box-shadow: none!important;
  &.v-card {
    &.v-card--flat {
      box-shadow: none!important;
    }
  }
}
.v-sheet,
.v-card {
  &:not(.v-list) {
    box-shadow: 0px 5px 15px 5px rgba(58, 28, 0, 0.03)!important;
  }
}
.v-input--dense {
  &.h-small {
    .v-input__control {
      .v-input__slot {
        min-height: 35px!important;
      }
    }
  }
}

.v-application {
  .v-snack {
    .v-snack__wrapper {
      background: #e0e0e0;
    }
  }
}
.who-am-i {
  background: #ff0000;
  color: #fff;
  border-radius: 6px;
  margin-left: 4px;
  font-weight: normal;
  padding: 0px 4px 1px 1px;
}
.hide-input {
  .v-select__selections {
    input {
      display: none!important;
    }
  }
}
</style>
