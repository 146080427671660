import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import L from 'leaflet'
import QRCodeStyling from 'qr-code-styling'
import html2canvas from 'html2canvas'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

function StrToArray (str) {
  if (str) {
    try {
      str = JSON.parse(str)
    } catch {
      str = []
    }
    return str
  }
  return []
}

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#2D3343',
        secondary: '#3A3F52',
        accent: '#3A3F52',
        error: '#b71c1c'
      },
      light: {
        primary: '#C6893F',
        secondary: '#b0bec5',
        accent: '#ffffff',
        error: '#b71c1c'
      }
    }
  }
})

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image: '/icon.png',
  dotsOptions: {
    color: '#C6893F',
    type: 'rounded'
  },
  backgroundOptions: {
    color: '#fff'
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 0
  }
})

Vue.prototype.$role = function (roles, role) {
  if (role === 'root' || role === 'admin') {
    return true
  } else {
    return roles.find(item => item === role)
  }
}

Vue.filter('price', function (value) {
  if (!value) {
    return 'Rp 0'
  }
  value = parseInt(value).toLocaleString()
  return 'Rp ' + value
})

Vue.prototype.$price = function (value) {
  if (!value) {
    return 'Rp 0'
  }
  value = parseInt(value).toLocaleString()
  return 'Rp ' + (value ? value.replace(',', '.') : value)
}

Vue.prototype.$formatMoney = function (amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount
    const negativeSign = amount < 0 ? '-' : ''
    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    const j = (i.length > 3) ? i.length % 3 : 0
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
  } catch (e) {
    return null
  }
}

Vue.prototype.$localDT = function (utcDate, type) {
  const localDate = utcDate ? new Date(utcDate) : new Date()
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  // const year = localDate.getFullYear().toString().substr(-2)
  const year = localDate.getFullYear().toString()
  const month = monthNames[localDate.getMonth()]
  const day = localDate.getDate()
  const dayName = dayNames[localDate.getDay()]

  /* eslint-disable-next-line */
  const seconds = localDate.getSeconds()
  const minutes = localDate.getMinutes()
  const hour = localDate.getHours()

  if (type === 'daydate') {
    return dayName + ', ' + (day < 10 ? '0' + day : day) + ' ' + month + ' ' + year
  } else if (type === 'number') {
    return (day < 10 ? '0' + day : day) + '' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '' + year.substr(2, 2)
  } else if (type === 'date') {
    return dayName + ' ' + month + ', ' + year
  } else if (type === 'display') {
    return month + ' ' + (day < 10 ? '0' + day : day) + ', ' + year
  } else if (type === 'datelocal') {
    return (day < 10 ? '0' + day : day) + '/' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '/' + year
  } else if (type === 'monthyear') {
    return month + ', ' + year
  } else if (type === 'datedefault') {
    return year + '-' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '-' + (day < 10 ? '0' + day : day)
  } else if (type === 'datetimedefault') {
    return year + '-' + (localDate.getMonth() + 1) + '-' + day + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else if (type === 'time') {
    return (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else if (type === 'datetime') {
    return dayName + ', ' + day + ' ' + month + ' ' + year + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else {
    return localDate
  }
}

Vue.prototype.$NumberOnly = function (e) {
  const key = e.keyCode ? e.keyCode : e.which
  if (isNaN(String.fromCharCode(key)) && key !== 8 && key !== 46 && key !== 37 && key !== 39 && key !== 45) {
    e.preventDefault()
    return false
  }
}

Vue.prototype.$validateEmail = function (email) {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

Vue.prototype.$reformatPhone = function (phone) {
  if (phone) {
    phone = phone.replace('+', '')
    if (phone.substring(0, 1) === '8') {
      return phone
    } else if (phone.substring(0, 2) === '08') {
      return phone.substring(1, phone.length)
    } else if (phone.substring(0, 2) === '62') {
      return phone.substring(2, phone.length)
    } else if (phone.substring(0, 3) === '+62') {
      return phone.substring(3, phone.length)
    } else {
      return phone
    }
  } else {
    return phone
  }
}

Vue.prototype.$phone = function (phone) {
  if (phone) {
    if (parseInt(phone.substr(0, 1)) === 0) {
      return phone
    } else if (parseInt(phone.substr(0, 1)) === 8) {
      return '62' + phone
    }
  }
  return phone || ''
}

Vue.prototype.$validatePhone = function (phone, arePhone) {
  if (parseInt(phone)) {
    if (phone.length > 7 && phone.length < 15) {
      return true
      // if (arePhone === '0' && phone.length > 9 && phone.length < 14) {
      //   if (phone.substring(0, 1) === '0') {
      //     return true
      //   } else {
      //     return false
      //   }
      // } else if (arePhone) {
      //   if (phone.substring(0, 1) === '2' || phone.substring(0, 2) === '02' || phone.substring(0, 2) === '62' || phone.substring(0, 3) === '+62') {
      //     return true
      //   } else {
      //     return false
      //   }
      // } else if (phone.substring(0, 1) === '8' || phone.substring(0, 2) === '08' || phone.substring(0, 2) === '62' || phone.substring(0, 3) === '+62') {
      //   return true
      // } else {
      //   return false
      // }
    } else {
      return false
    }
  } else {
    return false
  }
}

Vue.prototype.$getImage = function (featured, index) {
  try {
    const newGalleries = JSON.parse(featured)
    if (newGalleries.length) {
      return newGalleries[index || 0]
    } else {
      return '/images/no-image.jpg'
    }
  } catch {
    return '/images/no-image.jpg'
  }
}

Vue.prototype.$getImageAll = function (galleriesString) {
  try {
    const newGalleries = JSON.parse(galleriesString)
    if (newGalleries.length) {
      return newGalleries
    } else {
      return ['/images/no-image.jpg']
    }
  } catch {
    return ['/images/no-image.jpg']
  }
}

Vue.prototype.$JsonToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      json = json || ''
    }
  }
  return json
}

Vue.prototype.$StrToJson = function (str) {
  if (str) {
    try {
      str = JSON.parse(str)
    } catch {
      str = []
    }
    return str
  }
  return []
}

Vue.prototype.$ArrayToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      json = json || '[]'
    }
    return json
  }
  return '[]'
}

Vue.prototype.$StrToArray = function (str) {
  return StrToArray(str)
}

Vue.prototype.$StockMissing = function (v) {
  if (parseInt(v) === 1) {
    return 'Lost'
  } else if (parseInt(v) === 2) {
    return 'Damaged/Defective'
  }
  return '?'
}

Vue.prototype.$StockLimitColor = function (limit, currentStock) {
  limit = parseInt(limit) || 0
  currentStock = parseInt(currentStock) || 0
  // const green = limit ? ((200 / 100) * limit) : 200
  const orange = limit ? ((150 / 100) * limit) : 150
  if (currentStock <= 0) {
    return 'red darken-4 white--text'
  } else if (currentStock <= limit) {
    return 'red white--text'
  } else if (currentStock <= orange) {
    return 'orange darken-1 white--text'
  } else if (currentStock > orange) {
    return 'green darken-1 white--text'
  }
  return ''
}

Vue.prototype.$copyText = function (element) {
  if (typeof element === 'string') {
    let newEl = document.getElementById('temp-url-copy')
    if (!newEl) {
      newEl = document.createElement('span')
      document.body.appendChild(newEl)
    }
    newEl.setAttribute('id', 'temp-url-copy')
    newEl.innerText = element
    element = newEl
  }
  let range
  let selection
  if (document.body.createTextRange) {
    range = document.body.createTextRange()
    range.moveToElementText(element)
    range.select()
  } else if (window.getSelection) {
    selection = window.getSelection()
    range = document.createRange()
    range.selectNodeContents(element)
    selection.removeAllRanges()
    selection.addRange(range)
  }
  try {
    return document.execCommand('copy')
  } catch (err) {
    /* eslint-disable */
    console.log(err)
  }
}

Vue.prototype.$SellPrice = function (item, role) {
  const buyPrice = item['price_' + role]
  if (buyPrice) {
    return parseFloat(buyPrice)
  } else if (parseFloat(item.buyer)) {
    return parseFloat(item.price) || parseFloat(item.label_price)
  }
  return parseInt(item.price)
}

Vue.prototype.$BuyPrice = function (item, role) {
  const buyPrice = item['price_' + role + '_lead']
  if (buyPrice) {
    return parseFloat(buyPrice)
  } else if (parseFloat(item.buyer)) {
    return parseFloat(item.price) || parseFloat(item.label_price)
  }
  return parseFloat(item.price) || parseFloat(item.label_price)
}

Vue.prototype.$Cart = function (pricing, cartSubtotal, cartSubtotalLabelPrice) {
  const result = {
    value: 0,
    badge: '',
    count_label_price: 0
  }
  pricing = pricing.sort((a, b) => ((parseInt(a.value_minimum) || 0) > (parseInt(b.value_minimum) || 0)) ? 1 : -1)
  let getPrice = null
  for (let i = 0; i < pricing.length; i++) {
    const fromLabelPrice = parseInt(pricing[i].count_label_price)
    const min = parseInt(pricing[i].value_minimum)
    if ((fromLabelPrice && min <= parseInt(cartSubtotalLabelPrice)) || (!fromLabelPrice && min <= parseInt(cartSubtotal))) {
      getPrice = Object.assign({}, pricing[i])
    }
  }

  if (getPrice) {
    result.count_label_price = parseInt(getPrice.count_label_price) || 0
    if (getPrice.type === 'percent') {
      if (parseInt(getPrice.count_label_price)) {
        result.value = parseFloat(getPrice.value_discount) <= 100 ? ((parseFloat(getPrice.value_discount) || 0) / 100) * parseInt(cartSubtotalLabelPrice) : parseInt(cartSubtotalLabelPrice)
        result.badge = parseFloat(getPrice.value_discount)
      } else {
        result.value = parseFloat(getPrice.value_discount) <= 100 ? ((parseFloat(getPrice.value_discount) || 0) / 100) * parseInt(cartSubtotal) : parseInt(cartSubtotal)
        result.badge = parseFloat(getPrice.value_discount)
      }
    } else if (getPrice.type === 'price') {
      result.value = parseInt(getPrice.value_discount) || 0
      result.badge = 'Rp' + parseInt(result.value).toLocaleString()
    }
  }
  return result
}

Vue.prototype.$totalWeight = function (mycarts, weight) {
  if (mycarts) {
    let tWeight = 0
    mycarts.map(r => {
      tWeight += ((weight ? r.weight : r.product_weight) * r.quantity)
    })
    // 200 is Tolerant for Shipping
    tWeight = tWeight / 1000
    if ((tWeight % 1) > 0.2) {
      tWeight = Math.ceil(tWeight)
    } else {
      tWeight = Math.floor(tWeight)
    }
    if (tWeight < 1) {
      tWeight = 1
    }
    return tWeight
  } else {
    return 1
  }
}

Vue.prototype.$SHOW_PRICING_FOR = function (role, userRole) {
  if (role === 'distributor') {
    return userRole === 'pusat'
  } else if (role === 'agen') {
    return userRole === 'pusat' || userRole === 'distributor'
  } else if (role === 'reseller') {
    return userRole === 'pusat' || userRole === 'distributor' || userRole === 'agen'
  } else if (role === 'customer') {
    return userRole === 'pusat' || userRole === 'distributor' || userRole === 'agen' || userRole === 'reseller'
  } else {
    return false
  }
}

Vue.prototype.$TodayDate = function () {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  const todaydate = yyyy + '-' + mm + '-' + dd
  return todaydate
}

Vue.prototype.$DiffHours = function (dt, dtt) {
  const dt2 = new Date(dt)
  const dt1 = dtt ? new Date(dtt) : new Date()
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60)
  return Math.abs(Math.round(diff))
}

Vue.prototype.$DateReformat = function (dt) {
  const ndate = new Date(dt)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const year = ndate.getFullYear().toString().substr(-2)
  const month = monthNames[ndate.getMonth()]
  const day = ndate.getDate()
  return day + ' ' + month + ' ' + year
}

Vue.prototype.$DateRangeDays = function (fromd, tod) {
  const from = new Date(fromd)
  const to = new Date(tod)
  return parseInt(((from - to) / 86400000))
}

Vue.prototype.$DateAddDays = function (dt, days) {
  const date = new Date(dt)
  date.setDate(date.getDate() + days)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  const ddt = yyyy + '-' + mm + '-' + dd
  return ddt
}

Vue.prototype.$DateDiffDays = function (dt, days) {
  const date = new Date(dt)
  date.setDate(date.getDate() - days)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  const ddt = yyyy + '-' + mm + '-' + dd
  return ddt
}

Vue.prototype.$LastMonth = function (length) {
  const dateObj = new Date()
  const dateStrings = []
  const dateFormatOptions = {
    month: 'short',
    year: 'numeric'
  }
  for (var i = 0; i < (length || 3); ++i) {
    dateStrings.unshift(dateObj.toLocaleString('en-US', dateFormatOptions))
    dateObj.setMonth(dateObj.getMonth() - 1)
  }
  return dateStrings || []
}

Vue.prototype.$resizeImage = function (base64Str, maxWidth = 750, maxHeight = 750) {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = base64Str
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height
        height = MAX_HEIGHT
      }
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL())
    }
  })
}

Vue.prototype.$imgtoBase64 = function (event, callback, direct) {
  /* eslint-disable */
  function getType(ext) {
    if (ext === 'mov' || ext === 'mp4' || ext === 'avi' || ext === 'flv') {
      return 'video'
    } else if (ext === 'doc' || ext === 'docx' || ext === 'ppt' || ext === 'pptx' || ext === 'xls' || ext === 'xlsx' || ext === 'csv' || ext === 'txt' || ext === 'pdf' || ext === 'psd') {
      return 'doc'
    } else if (ext === 'jpg' || ext === 'jpeg' || ext === 'gif' || ext === 'png' || ext === 'svg') {
      return 'photo'
    } else if (ext === 'mp3' || ext === 'wav') {
      return 'audio'
    } else {
      return 'unknown'
    }
  }
  let r = {
    status: false,
    ext: '',
    type: '',
    data: null
  }
  let f = direct ? event : (event.target.files || event.dataTransfer.files)[0]

  const reader = new FileReader()
  if (f) {
    const fname = f.name
    const lastDot = fname.lastIndexOf('.')
    r.ext = fname.substring(lastDot + 1)
    r.type = getType(r.ext)

    const fSize = r.ext === 'mov' || r.ext === 'mp4' || r.ext === 'avi' || r.ext === 'flv' || r.ext === 'pdf' ? 50000000 : 5000000 // 50MB : 5MB

    if (f.size <= fSize) {
      reader.readAsDataURL(f)
      reader.onload = function (ev) {
        r.status = true
        r.data = ev.target.result
        callback(r)
      }
      reader.onerror = function (error) {
        r.status = false
        r.data = error
        callback(r)
      }
    } else {
      r.status = false
      r.data = 'file_size'
      callback(r)
    }
  } else {
    r.status = false
    r.data = 'canceled'
    callback(r)
  }
}

Vue.prototype.$MapCurve = function (map, latlng1, latlng2, color) {
  var latlngs = [];
  var offsetX = latlng2[1] - latlng1[1],
    offsetY = latlng2[0] - latlng1[0];

  var r = Math.sqrt( Math.pow(offsetX, 2) + Math.pow(offsetY, 2) ),
    theta = Math.atan2(offsetY, offsetX);

  var thetaOffset = (3.14/10);

  var r2 = (r/2)/(Math.cos(thetaOffset)),
    theta2 = theta + thetaOffset;

  var midpointX = (r2 * Math.cos(theta2)) + latlng1[1],
    midpointY = (r2 * Math.sin(theta2)) + latlng1[0];

  var midpointLatLng = [midpointY, midpointX];

  latlngs.push(latlng1, midpointLatLng, latlng2);

  var pathOptions = {
    color: color || 'rgba(255, 75, 75, .9)',
    weight: 1
  }

  if (typeof document.getElementById('map').animate === "function") { 
  	var durationBase = 1500;
    var duration = Math.sqrt(Math.log(r)) * durationBase;
    pathOptions.animate = {
      duration: duration || 0,
      iterations: 1,
      easing: 'ease-in-out',
      direction: 'alternate'
    }
  }

  L.curve([
    'M', latlng1,
    'Q', midpointLatLng,
      latlng2
  ], pathOptions).addTo(map);
}

// const header = data.blocks[0].type === 'header' ? data.blocks[0].data.text || '' : ''
// const paragraph = data.blocks[0].type === 'paragraph' ? data.blocks[0].data.text || '' : ''
// const quote = data.blocks[0].type === 'quote' ? data.blocks[0].data.text || '' : ''
// const code = data.blocks[0].type === 'code' ? data.blocks[0].data.code || '' : ''
// const list = data.blocks[0].type === 'list' ? data.blocks[0].data.items.length ? data.blocks[0].data.items[0] || '' : '' : ''
// const image = data.blocks[0].type === 'image' ? data.blocks[0].data.file.url || '' : ''

Vue.prototype.$EditorParse = function (json) {
  let result = false
  if (json) {
    if (json.substr(0, 1) === '[' && json.substr(json.length - 1, 1) === ']') {
      try {
        result = true
        json = JSON.parse(json)
      } catch {
        result = false
      }
    } else {
      result = false
    }
  } else {
    result = false
  }
  if (result) {
    let str = ''
    for (let i = 0; i < json.length; i++) {
      const j = json[i]
      if (j.type === 'header') {
        str += `<h` + (j.data.level || 4) + `>` + j.data.text + `</h` + (j.data.level || 4) + `>`
      } else if (j.type === 'paragraph') {
        str += `<p>` + j.data.text + `</p>`
      } else if (j.type === 'quote') {
        if (j.data.caption) {
          str += `<label>` + j.data.caption + `</label>`
        }
        str += `<blockquote>` + j.data.text + `</blockquote>`
      } else if (j.type === 'code') {
        str += `<code>` + j.data.code + `</code>`
      } else if (j.type === 'list') {
        if (j.data.style === 'ordered') {
          str += `<ol>`
        } else {
          str += `<ul>`
        }
        for (let k = 0; k < j.data.items.length; k++) {
          str += `<li>` + j.data.items[k] + `</li>`
        }
        if (j.data.style === 'ordered') {
          str += `</ol>`
        } else {
          str += `</ul>`
        }
      } else if (j.type === 'image') {
        str += `<img src="` + j.data.file.url + `" alt="` + (j.data.caption || `eBright Content`) + `" class="` + (j.data.withBorder ? `with-border ` : ``) + (j.data.withBackground ? `with-background ` : ``) + (j.data.stretched ? `stretched` : ``) + `">`
      }
    }
    return str
  } else {
    return json || ''
  }
}

Vue.prototype.$withEmoticon = function (str) {
  if (str) {
    return str
      .split(':)').join('😊')
      .split(':D').join('😁').split(':d').join('😁')
      .split('XD').join('😆').split('xd').join('😆')
      .split(';)').join('😆').split('xd').join('😆')
      .split(':*').join('😘')
      .split(':(').join('😢')
      .split(':|').join('😐')
      .split('B)').join('😎')
      .split('-_-').join('😑')
      .split(';)').join('😉')
      .split(':p').join('😋').split(':P').join('😋')
  } else {
    return ''
  }
}

Vue.prototype.$emoticonToString = function (str, emoticons) {
  if (str && emoticons) {
    if (emoticons.length) {
      for (let e = 0; e < emoticons.length; e++) {
        str = str.split(emoticons[e][1]).join(emoticons[e][0])
      }
    }
  }
  return str
}

Vue.prototype.$stringToEmoticon = function (str, emoticons) {
  if (str && emoticons) {
    if (emoticons.length) {
      for (let e = 0; e < emoticons.length; e++) {
        str = str.split(emoticons[e][0]).join(emoticons[e][1])
      }
    }
  }
  return str
}

Vue.prototype.$StipHTML = function (html) {
   let tmp = document.createElement('div')
   tmp.innerHTML = html;
   const result = ((tmp.textContent || tmp.innerText || '') + ' ').trim()
   if (!result && tmp.innerHTML) {
     if (tmp.innerHTML.match(/<img/gi)) {
       return true
     }
     return result
   }
   return result
}

Vue.prototype.$passwordCheck = function (password) {
  if (!password) {
    return 0
  }
  let strength = 0
  if (password.match(/[a-z]+/)){
    strength += 1
  }
  if (password.match(/[A-Z]+/)){
    strength += 1
  }
  if (password.match(/[0-9]+/)){
    strength += 1
  }
  if (password.match(/[$@#&!]+/)){
    strength += 1
  }
  if (strength === 4) {
    return 100
  } else if (strength === 3) {
    return 75
  } else if (strength === 2) {
    return 50
  } else if (strength === 1) {
    return 25
  } else {
    return 0
  }
}

Vue.prototype.$insertAfter = function (referenceNode, newNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

Vue.prototype.$autocomplete = function (v) {
  let tagArr = document.getElementsByTagName("input");
  for (let i = 0; i < tagArr.length; i++) {
    tagArr[i].autocomplete = v || 'off';
  }
}

Vue.prototype.$USERNAME_CHECK = function (username) {
  if (username) {
    const usernameRegex = /^[a-zA-Z0-9._]+$/;
    return username.match(usernameRegex)
  }
  return null
}

Vue.prototype.$GEN_ID = function (user) {
  const txt = 'EBRIGHT' + ((new Date(user.created)).getFullYear() + '').substr(2, 2)
  let id = parseInt(user.id)
  if (id < 10) {
    id = '0000' + id
  } else if (id < 100) {
    id = '000' + id
  } else if (id < 1000) {
    id = '00' + id
  } else if (id < 10000) {
    id = '0' + id
  } else {
    id = '' + id
  }
  return (txt + '.' + id)
}

Vue.prototype.$Qr = function (data) {
  return Object.assign({ qr: qrCode })
}

Vue.prototype.$Capture = function (element) {
  return html2canvas(element, {
    backgroundColor: null
  })
}

Vue.prototype.$getThisMonth = function (dtcustom) {
  const formattedDate = function (date) {
    const m = ('0' + (date.getMonth() + 1)).slice(-2)
    const d = ('0' + date.getDate()).slice(-2)
    const y = date.getFullYear()
    return y + '-' + m + '-' + d
  }
  const currDate = dtcustom ? new Date(dtcustom) : new Date()
  const firstDay = new Date(currDate.getFullYear(), currDate.getMonth(), 1)
  const lastDay = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0)
  const monthStartDate = formattedDate(firstDay)
  const monthEndDate = formattedDate(lastDay)

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  return {
    from: monthStartDate,
    to: monthEndDate,
    lastDateDay: lastDay.getDate(),
    lastDateDayName: dayNames[lastDay.getDay()],
    lastDateMonth: lastDay.getMonth() + 1,
    lastDateYear: lastDay.getFullYear()
  }
}

Vue.prototype.$roles = function (roles, slug) {
  console.log('[roles slug]', roles, slug)
  if (slug === '/') {
    return true
  }
  if (!roles || roles === '[]') {
    return true
  } else {
    const s = slug.replace('/', '')
    if (typeof roles === 'string') {
      return StrToArray(roles).find(x => x === s)
    } else {
      return roles.find(x => x === s)
    }
  }
}

Vue.prototype.$roleColor = function (role) {
  return role === 'pusat' ? 'black' : role === 'distributor' ? 'purple' : role === 'agen' ? 'pink' : role === 'reseller' ? 'orange' : role === 'customer' ? 'teal' : 'blue darken-4'
}

Vue.prototype.$downloadAsCSV = function (rows, fields, fieldsKeys, filename) {
  let csvContent = 'data:text/csv;charset=utf-8,' + fields + '\r\n'
  let row = ''
  rows.forEach(function(r) {
    row += r[fieldsKeys[0]]
    for (let i = 1; i < fieldsKeys.length; i++) {
      row += ';' + r[fieldsKeys[i]]
    }
    row += '\r\n'
  })
  csvContent = csvContent + row
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'ebright-' + (filename || 'download') + '.csv')
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

Vue.prototype.$kFormatter = function (num) {
  if (num > 999999) {
    return Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'm'
  }
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}
