import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import(/* webpackChunkName: "transaction" */ '../views/Transaction.vue')
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import(/* webpackChunkName: "finance" */ '../views/Finance.vue')
  },
  // {
  //   path: '/Collaboration',
  //   name: 'Finance',
  //   component: () => import(/* webpackChunkName: "finance" */ '../views/Collaboration.vue')
  // },
  {
    path: '/reward',
    name: 'Reward',
    component: () => import(/* webpackChunkName: "reward" */ '../views/Reward.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue')
  },
  {
    path: '/packaging',
    name: 'Packaging',
    component: () => import(/* webpackChunkName: "packaging" */ '../views/Packaging.vue')
  },
  {
    path: '/knowledge-base',
    name: 'KnowledgeBase',
    component: () => import(/* webpackChunkName: "knowledge-base" */ '../views/KnowledgeBase.vue')
  },
  {
    path: '/module-learning',
    name: 'ModuleLearning',
    component: () => import(/* webpackChunkName: "module-learning" */ '../views/ModuleLearning.vue')
  },
  {
    path: '/biolink',
    name: 'Biolink',
    component: () => import(/* webpackChunkName: "biolink" */ '../views/Biolink.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import(/* webpackChunkName: "setting" */ '../views/Setting.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
