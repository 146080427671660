<template>
  <div class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-4 align-center justify-space-between">
      <span class="font-weight-bold">
        Overview
      </span>
      <span class="caption-small">
        Home > Overview
      </span>
    </div>
    <v-row no-gutters>
      <!-- <v-col v-if="false" cols="12" class="px-md-4 mt-md-0 mb-6">
        <v-card class="pa-4" style="color: #333!important;">
          <Map />
        </v-card>
      </v-col> -->
      <v-col cols="12" class="col-md-6 pt-6 pb-2 pb-md-6 px-0 px-md-4">
        <v-card class="d-flex justify-space-between flex-column flex-md-row accent px-4 pt-4 pb-4 overflow-card rounded-lg">
          <span class="overflow-card-item x1" />
          <div class="d-inline-flex flex-column">
            <div class="d-inline-flex" style="margin-top: -5px;">
              <v-card dark class="d-inline-flex blue mr-4 justify-center pa-4" style="height: 55px;width: 55px;margin-top: -25px;">
                <v-icon>
                  mdi-account-group
                </v-icon>
              </v-card>
              <div class="pb-2 subtitle-2 pt-1">
                Users
              </div>
            </div>
            <div class="caption-small pt-4">
              All Period's
            </div>
          </div>
          <div :class="[$vuetify.theme.dark ? 'grey--text text--lighten-4' : 'grey--text text--darken-2']" class="d-flex align-center justify-end font-weight-bold pr-4" style="font-size: 32px;">
            {{ GraphOverview.total_member }}
            <!-- {{ ParseTotalMember(GraphOverview.total_member, $store.state.user.role, true) }} -->
            <!-- <div class="caption primary--text" v-html="ParseTotalMember(GraphOverview.total_member, $store.state.user.role)" /> -->
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-md-6 pt-6 pb-2 pb-md-6 px-0 px-md-4">
        <v-card class="d-flex justify-space-between flex-column flex-md-row accent px-4 pt-4 pb-4 overflow-card rounded-lg">
          <span class="overflow-card-item x2" />
          <div class="d-inline-flex flex-column">
            <div class="d-inline-flex" style="margin-top: -5px;">
              <v-card dark class="d-inline-flex green mr-4 justify-center pa-4" style="height: 55px;width: 55px;margin-top: -25px;">
                <v-icon>
                  mdi-currency-usd
                </v-icon>
              </v-card>
              <div class="pb-2 subtitle-2 pt-1">
                Total Selling
              </div>
            </div>
            <div class="caption-small pt-4">
              All Period's
            </div>
          </div>
          <div :class="[$vuetify.theme.dark ? 'grey--text text--lighten-4' : 'grey--text text--darken-2']" class="d-flex align-center justify-end font-weight-bold pr-4" style="font-size: 32px;">
            {{ GraphOverview.total_sell | price }}
          </div>
        </v-card>
      </v-col>
      <!-- <v-col v-if="$store.state.user.role !== 'pusat'" cols="12" class="col-md-6 col-lg-3 px-md-4 mb-6">
        <v-card class="pa-4 text-center">
          <div class="title grey--text">
            {{ GraphOverview.total_buy | price }}
          </div>
          <div class="caption primary--text">
            Hpp
          </div>
        </v-card>
      </v-col>
      <v-col v-if="$store.state.user.role !== 'pusat'" cols="12" class="col-md-6 col-lg-3 px-md-4 mb-6">
        <v-card class="pa-4 text-center">
          <div class="title grey--text">
            {{ (GraphOverview.total_sell - GraphOverview.total_buy) | price }}
          </div>
          <div class="caption primary--text">
            Margin
          </div>
        </v-card>
      </v-col> -->
      <v-col cols="12" class="col-md-9 px-md-4 mt-md-0 pt-6 pt-md-0 mb-6">
        <v-card class="pa-4 accent rounded-lg" style="position: relative;">
          <div style="position: absolute;top: 5px;right:5px;z-index:1;max-width: 170px;">
            <v-select
              v-model="graphAreaSelected"
              :items="[
                { id: 'city', name: 'By City' },
                { id: 'province', name: 'By Province' }
              ]"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details
            />
          </div>
          <apexchart v-if="chartLoadedArea" ref="chartArea" type="bar" :options="chartOptionsArea" :series="chartSeriesArea" :height="$store.state.screenWidth < 768 ? 250 : 400" width="100%" />
        </v-card>
      </v-col>
      <v-col cols="12" class="col-md-3 px-md-4 mt-md-0 pt-2 pt-md-0 mb-4 mb-md-6" :style="{ height: ($store.state.screenWidth > 767 ? '447px' : 'auto') }" style="overflow-y: auto;">
        <div v-if="!parseInt($store.state.user.isstockist)">
          <div class="pb-4">
            <v-menu
              ref="menu"
              v-model="menu"
              :return-value.sync="dates"
              left
              offset-y
              :close-on-content-click="false"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateRangeText"
                  label="Offline Store Omzet Date Ranges"
                  append-icon="mdi-calendar-outline"
                  readonly
                  outlined
                  dense
                  hide-details
                  clearable
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dates = []"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                light
                class="force-light-font"
                range
              >
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="$refs.menu.save(dates)"
                >
                  Ok
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <v-card v-for="(m, iM) in stokistData.filter(r => parseInt(r.id) !== parseInt($store.state.user.id))" :key="'member-' + iM" :class="[m.color || 'green']" class="d-flex align-center pa-4 mb-4 rounded-lg" style="border: solid 1px #fff!important;">
            <div class="pr-2 pl-1">
              <v-avatar size="45" class="white rounded-circle mr-3">
                <img
                  alt="stokist"
                  :src="(m.user_img || '/images/no-image.jpg')"
                >
              </v-avatar>
            </div>
            <div class="force-light-font">
              <div class="caption-small font-weight-bold ">
                {{ m.user_name }}
              </div>
              <div class="subtitle-1 font-weight-bold">
                {{ $price(m.total) }}
              </div>
              <div class="caption-small">
                Offline Store Omzet
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" class="px-md-4 mt-md-0 pt-0 pt-md-0 mb-2 mb-md-6">
        <v-card class="pa-4 accent" style="position: relative;">
          <div style="position: absolute;top: 5px;right:5px;z-index:1;max-width: 170px;">
            <v-select
              v-if="!parseInt($store.state.user.isstockist)"
              v-model.number="graphDAU.month"
              :items="[{ id: 7, name: '7 Month' }, { id: 12, name: '1 Year' }, { id: 24, name: '2 Years' }, { id: 36, name: '3 Years' }]"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details
            />
          </div>
          <apexchart v-if="chartLoadedDAU" ref="chartdau" type="area" :options="chartOptionsDAU" :series="chartSeriesDAU" :height="$store.state.screenWidth < 768 ? 250 : 400" width="100%" />
        </v-card>
      </v-col>
      <v-col cols="12" class="px-md-4 mt-md-0 pt-0 pt-md-0 mb-2 mb-md-6">
        <v-card class="pa-4 accent" style="position: relative;">
          <div style="position: absolute;top: 5px;right:5px;z-index:1;max-width: 170px;">
            <v-select
              v-model.number="activePusat"
              :disabled="parseInt($store.state.user.isstockist) ? true : false"
              :items="parseInt($store.state.user.isstockist) ? [{ id: parseInt($store.state.user.id), user_name: $store.state.user.name }] : [{ id: 0, user_name: 'All Data' }].concat(stokistData)"
              item-text="user_name"
              item-value="id"
              dense
              outlined
              hide-details
            />
          </div>
          <apexchart v-if="chartLoaded" ref="chart" type="area" :options="chartOptions" :series="chartSeries" :height="$store.state.screenWidth < 768 ? 250 : 400" width="100%" />
        </v-card>
      </v-col>
      <!-- <v-col v-if="$store.state.user.role === 'distributor' || $store.state.user.role === 'agen'" cols="12" class="col-md-6 px-md-4 mt-md-0 pt-6 pt-md-0 mb-2 mb-md-6">
        <v-card class="accent rounded-lg">
          <v-card-title class="caption py-2" style="font-weight: 400;">
            5 Active Points & Rewards
          </v-card-title>
          <v-divider />
          <v-card-text class="px-4" style="height: 350px;overflow-y: auto;">
            <div v-for="(p, iP) in graph.rewards" :key="'product-' + iP">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-avatar size="45" class="grey rounded-lg mr-3">
                    <img
                      alt="user"
                      :src="(p.image || '/images/no-image.jpg')"
                    >
                  </v-avatar>
                  <span class="caption-small font-weight-bold c-pointer" @click.prevent="''">
                    {{ p.title }}
                    <div class="caption-small lh-clear" style="font-weight: 400;">
                      <v-icon class="body-2 mr-1" color="yellow darken-2" style="padding-bottom: 2px;">
                        mdi-gold
                      </v-icon>
                      {{ $formatMoney(parseInt(p.poin), 0, '.', ',') }} Point(s)
                    </div>
                  </span>
                </div>
                <div class="d-flex align-center caption-small font-weight-bold justify-center">
                  <v-btn v-if="countPoint(p.poin) < 100" depressed small dark color="blue" class="text-capitalize px-6 caption-small rounded-xl" to="/product">
                    Add Points
                  </v-btn>
                  <v-chip v-else-if="parseInt(p.user) === parseInt($store.state.user.id) && !parseInt(p.allow_multiple)" depressed small dark color="blue" class="text-capitalize px-6 caption-small rounded-xl" disabled="disabled">
                    Collected
                  </v-chip>
                  <v-chip v-else depressed small dark color="blue" class="text-capitalize px-6 caption-small rounded-xl">
                    Ready to Collect
                  </v-chip>
                </div>
              </div>
              <v-divider class="my-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" class="col-md-6 px-md-4 mt-md-0 pt-6 pt-md-0 mb-2 mb-md-6">
        <v-card class="accent rounded-lg">
          <v-card-title class="caption py-2" style="font-weight: 400;">
            Best 5 eBright Skin Product
          </v-card-title>
          <v-divider />
          <v-card-text class="px-4" style="height: 350px;overflow-y: auto;">
            <div v-for="(p, iP) in graph.productBest" :key="'product-' + iP">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-avatar size="45" class="grey rounded-lg mr-3">
                    <img
                      alt="user"
                      :src="$getImageAll(p.galleries)[0]"
                    >
                  </v-avatar>
                  <span class="caption-small font-weight-bold c-pointer" @click.prevent="''">
                    {{ p.name }}
                    <div class="caption-small lh-clear" style="font-weight: 400;">
                      Total: {{ parseInt(p.total || 0).toLocaleString() }}pcs
                    </div>
                  </span>
                </div>
                <div :class="[c(iP)]" class="d-flex align-center caption-small font-weight-bold justify-center rounded-circle" style="width: 35px;height: 35px;">
                  #{{ iP + 1 }}
                </div>
              </div>
              <v-divider class="my-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col cols="12" class="col-md-4 px-md-4 mt-md-0 pt-6 pt-md-0 mb-2 mb-md-6">
        <v-card class="accent rounded-lg">
          <v-card-title class="caption py-2 d-flex flex-column align-start" style="font-weight: 400;">
            Top 5 Selling Product
            <div v-if="!parseInt($store.state.user.isstockist)" class="font-weight-bold caption-small">
              {{ activePusat ? (stokistData.find(r => r.id === activePusat) ? stokistData.find(r => r.id === activePusat).user_name : '') : 'All Data' }}
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-4" style="height: 350px;overflow-y: auto;">
            <div v-for="(p, iP) in graph.product" :key="'product-' + iP">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-avatar size="45" class="white rounded-lg mr-3">
                    <img
                      alt="user"
                      :src="$getImageAll(p.galleries)[0]"
                    >
                  </v-avatar>
                  <span class="caption-small font-weight-bold c-pointer" @click.prevent="''">
                    {{ p.name }}
                    <div class="caption-small lh-clear" style="font-weight: 400;">
                      Total: {{ parseInt(p.total || 0).toLocaleString() }}pcs
                    </div>
                  </span>
                </div>
                <div :class="[c(iP)]" class="d-flex align-center caption-small font-weight-bold justify-center rounded-circle" style="width: 35px;height: 35px;">
                  #{{ iP + 1 }}
                </div>
              </div>
              <v-divider class="my-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col v-if="$store.state.user.role === 'pusat'" cols="12" class="col-md-6 px-md-4 mt-md-0 pt-6 pt-md-0 mb-2 mb-md-6">
        <v-card class="accent rounded-lg">
          <v-card-title class="caption py-2" style="font-weight: 400;">
            Top 5 Sales Distributor
          </v-card-title>
          <v-divider />
          <v-card-text class="px-4" style="height: 350px;overflow-y: auto;">
            <div v-for="(d, iD) in graph.sales.distributor" :key="'distributor-' + iD">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-avatar size="45" class="grey rounded-lg mr-3">
                    <img
                      alt="user"
                      :src="d.img || '/icon.png'"
                    >
                  </v-avatar>
                  <span class="caption-small font-weight-bold c-pointer lh-clear" @click.prevent="''">
                    {{ d.name }}
                    <v-chip
                      :class="[$roleColor(d.role)]"
                      class="rounded-sm"
                      style="font-weight: 400;"
                      x-small
                    >
                      {{ d.role }}
                    </v-chip>
                    <div class="mt-1" style="font-weight: 400;line-height: 145%!important;font-size: 9px;">
                      <div>
                        Omzet: {{ $price(d.omzet) }}
                      </div>
                      <div>
                        Hpp: {{ $price(d.hpp) }}
                      </div>
                      <div>
                        Margin: {{ $price(parseInt(d.omzet) - parseInt(d.hpp)) }}
                      </div>
                    </div>
                  </span>
                </div>
                <div :class="[c(iD)]" class="d-flex align-center caption-small font-weight-bold justify-center rounded-circle" style="width: 35px;height: 35px;">
                  #{{ iD + 1 }}
                </div>
              </div>
              <v-divider class="my-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col v-if="$store.state.user.role === 'pusat' || $store.state.user.role === 'distributor'" cols="12" class="col-md-6 px-md-4 mt-md-0 pt-6 pt-md-0 mb-2 mb-md-6">
        <v-card class="accent rounded-lg">
          <v-card-title class="caption py-2" style="font-weight: 400;">
            Top 5 Sales Agen
          </v-card-title>
          <v-divider />
          <v-card-text class="px-4" style="height: 350px;overflow-y: auto;">
            <div v-for="(d, iD) in graph.sales.agen" :key="'distributor-' + iD">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-avatar size="45" class="grey rounded-lg mr-3">
                    <img
                      alt="user"
                      :src="d.img || '/icon.png'"
                    >
                  </v-avatar>
                  <span class="caption-small font-weight-bold c-pointer lh-clear" @click.prevent="''">
                    {{ d.name }}
                    <v-chip
                      :class="[$roleColor(d.role)]"
                      class="rounded-sm"
                      style="font-weight: 400;"
                      x-small
                    >
                      {{ d.role }}
                    </v-chip>
                    <div class="mt-1" style="font-weight: 400;line-height: 145%!important;font-size: 9px;">
                      <div>
                        Omzet: {{ $price(d.omzet) }}
                      </div>
                      <div>
                        Hpp: {{ $price(d.hpp) }}
                      </div>
                      <div>
                        Margin: {{ $price(parseInt(d.omzet) - parseInt(d.hpp)) }}
                      </div>
                    </div>
                  </span>
                </div>
                <div :class="[c(iD)]" class="d-flex align-center caption-small font-weight-bold justify-center rounded-circle" style="width: 35px;height: 35px;">
                  #{{ iD + 1 }}
                </div>
              </div>
              <v-divider class="my-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col v-if="$store.state.user.role === 'pusat' || $store.state.user.role === 'distributor' || $store.state.user.role === 'agen'" cols="12" class="col-md-4 px-md-4 mt-md-0 pt-6 pt-md-0 mb-2 mb-md-6">
        <v-card class="accent rounded-lg">
          <v-card-title class="caption py-2 d-flex flex-column align-start" style="font-weight: 400;">
            Top 5 Reseller
            <div v-if="!parseInt($store.state.user.isstockist)" class="font-weight-bold caption-small">
              {{ activePusat ? (stokistData.find(r => r.id === activePusat) ? stokistData.find(r => r.id === activePusat).user_name : '') : 'All Data' }}
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-4" style="height: 350px;overflow-y: auto;">
            <div v-for="(d, iD) in graph.sales.reseller" :key="'distributor-' + iD">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-avatar size="45" class="white rounded-lg mr-3">
                    <img
                      alt="user"
                      :src="d.buyer_img || '/icon.png'"
                    >
                  </v-avatar>
                  <span class="caption-small font-weight-bold c-pointer lh-clear" @click.prevent="''">
                    {{ d.buyer_name }}
                    <v-chip
                      :class="[$roleColor(d.buyer_role)]"
                      dark
                      class="rounded-sm"
                      style="font-weight: 400;"
                      x-small
                    >
                      {{ d.buyer_role }}
                    </v-chip>
                    <div class="mt-1" style="font-weight: 400;line-height: 145%!important;font-size: 9px;">
                      <div>
                        Buy: {{ $price(d.total) }}
                      </div>
                      <!-- <div>
                        Hpp: {{ $price(d.hpp) }}
                      </div> -->
                      <!-- <div>
                        Margin: {{ $price(parseInt(d.omzet) - parseInt(d.hpp)) }}
                      </div> -->
                    </div>
                  </span>
                </div>
                <div :class="[c(iD)]" class="d-flex align-center caption-small font-weight-bold justify-center rounded-circle" style="width: 35px;height: 35px;">
                  #{{ iD + 1 }}
                </div>
              </div>
              <v-divider class="my-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-md-4 px-md-4 mt-md-0 pt-6 pt-md-0 mb-2 mb-md-6">
        <v-card class="accent rounded-lg">
          <v-card-title class="caption py-2 d-flex align-start flex-column" style="font-weight: 400;">
            Top 5 Customer
            <div v-if="!parseInt($store.state.user.isstockist)" class="font-weight-bold caption-small">
              {{ activePusat ? (stokistData.find(r => r.id === activePusat) ? stokistData.find(r => r.id === activePusat).user_name : '') : 'All Data' }}
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-4" style="height: 350px;overflow-y: auto;">
            <div v-for="(d, iD) in graph.buyer.customer" :key="'distributor-' + iD">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-avatar size="45" class="white rounded-lg mr-3">
                    <img
                      alt="user"
                      :src="d.buyer_img || '/icon.png'"
                    >
                  </v-avatar>
                  <span class="caption-small font-weight-bold c-pointer lh-clear" @click.prevent="''">
                    {{ d.buyer_name }}
                    <v-chip
                      :class="[$roleColor(d.buyer_role)]"
                      dark
                      class="rounded-sm"
                      style="font-weight: 400;"
                      x-small
                    >
                      {{ d.buyer_role }}
                    </v-chip>
                    <div class="mt-1" style="font-weight: 400;line-height: 145%!important;font-size: 9px;">
                      <div>
                        Buy: {{ $price(d.total) }}
                      </div>
                    </div>
                  </span>
                </div>
                <div :class="[c(iD)]" class="d-flex align-center caption-small font-weight-bold justify-center rounded-circle" style="width: 35px;height: 35px;">
                  #{{ iD + 1 }}
                </div>
              </div>
              <v-divider class="my-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: (vm) => ({
    activePusat: parseInt(vm.$store.state.user.id),
    dates: [],
    menu: false,
    chartLoadedArea: false,
    graphAreaSelected: 'city',
    chartOptionsArea: {
      id: 'chartArea',
      type: 'bar',
      title: {
        enable: false,
        text: 'Most Active City',
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Montserrat, Arial, sans-serif',
          color: '#ffffff'
        }
      },
      chart: {
        stacked: false,
        fontFamily: 'Montserrat, Arial, sans-serif',
        toolbar: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return '<div class="orange">' +
            '<div class="pt-1 px-2 caption-small font-weight-bold">' + vm.chartOptionsArea.xaxis.categories[dataPointIndex] + '</div><div class="px-2 pb-1">' + series[seriesIndex][dataPointIndex] + '</span>' +
            '</div>'
        },
        style: {
          fontSize: '12px'
        },
        y: {
        }
      },
      grid: {
        show: true,
        borderColor: vm.$vuetify.theme.dark ? '#555555' : '#f7f7f7',
        strokeDashArray: 2,
        position: 'back',
        row: {
          // colors: ['#fe4a49', '#2ab7ca', '#fed766', '#e6e6ea', '#f4f4f8'],
          opacity: 0.1
        },
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        column: {
          // colors: ['#fe4a49', '#2ab7ca', '#fed766', '#e6e6ea', '#f4f4f8'],
          opacity: 0.1
        },
        padding: {
          top: 10,
          right: 20,
          bottom: 0,
          left: 10
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false,
        orientation: 'horizontal',
        formatter: function (val) {
          return (val ? val.toLocaleString() : 0)
        },
        offsetY: -10,
        background: {
          borderWidth: 0
        },
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          colors: ['#828282', '#FFA500', '#fed766', '#2ab7ca', '#fe4a49']
        }
      },
      fill: {
        colors: ['rgba(3, 186, 241, 1)']
      },
      stroke: {
        width: 1,
        curve: 'smooth',
        colors: ['#828282', '#FFA500', '#fed766', '#2ab7ca', '#fe4a49']
      },
      xaxis: {
        categories: [],
        range: 10,
        labels: {
          style: {
            border: true,
            fontSize: '9px',
            colors: '#eeeeee'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#FFFFFF',
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        title: {
          style: {
            color: '#FFFFFF'
          }
        }
      },
      yaxis: {
        range: 10,
        labels: {
          style: {
            fontSize: '10px',
            colors: '#FFFFFF'
          },
          formatter: (v) => {
            return v.toLocaleString()
          }
        }
      }
    },
    chartSeriesArea: [10, 30, 25, 34, 50],
    chartLoaded: false,
    chartOptions: {
      id: 'chart',
      title: {
        enable: false,
        text: 'Penjualan',
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Montserrat, Arial, sans-serif',
          color: '#ffffff'
        }
      },
      chart: {
        stacked: false,
        fontFamily: 'Montserrat, Arial, sans-serif',
        toolbar: {
          show: false
        }
        // events: {
        //   click: vm.ChartClick
        // }
      },
      tooltip: {
        theme: 'dark',
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return '<div class="blue">' +
            '<div class="pt-1 px-2 caption-small font-weight-bold">' + vm.chartOptions.xaxis.categories[dataPointIndex] + '</div><div class="px-2 pb-1">' + vm.$price(series[seriesIndex][dataPointIndex]) + '</span>' +
            '</div>'
        },
        style: {
          fontSize: '12px'
        },
        y: {
          // formatter: vm.labelFormat,
          // title: {
          //   formatter: (seriesName) => seriesName
          // }
        }
      },
      grid: {
        show: true,
        borderColor: vm.$vuetify.theme.dark ? '#777777' : '#f0f0f0',
        position: 'back',
        row: {
          // colors: ['#fe4a49', '#2ab7ca', '#fed766', '#e6e6ea', '#f4f4f8'],
          opacity: 0.1
        },
        column: {
          // colors: ['#fe4a49', '#2ab7ca', '#fed766', '#e6e6ea', '#f4f4f8'],
          opacity: 0.1
        },
        padding: {
          top: 10,
          right: 20,
          bottom: 0,
          left: 10
        }
      },
      legend: {
        fontSize: '12px',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: '#777'
        },
        itemMargin: {
          horizontal: 20,
          vertical: 7
        },
        markers: {
          width: 12,
          height: 12
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        orientation: 'horizontal',
        formatter: function (val) {
          return 'Rp' + (val ? val.toLocaleString() : 0)
        },
        offsetY: -10,
        background: {
          borderWidth: 0
        },
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          colors: ['#828282', '#FFA500', '#fed766', '#2ab7ca', '#fe4a49']
        }
      },
      fill: {
        colors: ['#FFD8DD']
      },
      stroke: {
        width: 1,
        curve: 'smooth',
        colors: ['#828282', '#FFA500', '#fed766', '#2ab7ca', '#fe4a49']
      },
      annotations: {
        // xaxis: [
        //   {
        //     x: vm.$DateReformat(vm.$TodayDate()),
        //     borderColor: '#C6893F',
        //     strokeDashArray: 1,
        //     label: {
        //       borderColor: '#C6893F',
        //       style: {
        //         fontSize: '10px',
        //         color: '#fff',
        //         background: '#C6893F'
        //       },
        //       orientation: 'horizontal',
        //       offsetY: 7,
        //       text: 'Today'
        //     }
        //   }
        // ]
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            border: true,
            fontSize: '9px',
            colors: '#eeeeee'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#FFFFFF',
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        title: {
          style: {
            color: '#FFFFFF'
          }
        }
      },
      yaxis: {
        min: 0,
        labels: {
          style: {
            fontSize: '10px',
            colors: '#FFFFFF'
          },
          formatter: (v) => {
            return 'Rp' + v.toLocaleString()
          }
        }
      }
    },
    chartSeries: [],
    GraphMonth: 12,
    GraphOverview: {
      id: 0,
      total_member: '',
      total_sell: 0,
      total_buy: 0
    },
    graph: {
      area: [],
      product: [],
      productBest: [],
      rewards: [],
      buyer: {
        customer: []
      },
      sales: {
        distributor: [],
        agen: [],
        reseller: []
      }
    },
    stokistData: [],
    graphDAU: {
      month: 7
    },
    chartLoadedDAU: false,
    chartSeriesDAU: [],
    chartOptionsDAU: {
      id: 'chart',
      title: {
        enable: false,
        text: 'User Acquisition',
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Montserrat, Arial, sans-serif',
          color: '#ffffff'
        }
      },
      chart: {
        stacked: false,
        fontFamily: 'Montserrat, Arial, sans-serif',
        toolbar: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        style: {
          fontSize: '12px'
        },
        y: {
        }
      },
      grid: {
        show: true,
        borderColor: vm.$vuetify.theme.dark ? '#777777' : '#f0f0f0',
        position: 'back',
        row: {
          opacity: 0.1
        },
        column: {
          opacity: 0.1
        },
        padding: {
          top: 10,
          right: 20,
          bottom: 0,
          left: 10
        }
      },
      legend: {
        fontSize: '12px',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: '#777'
        },
        itemMargin: {
          horizontal: 20,
          vertical: 7
        },
        markers: {
          width: 12,
          height: 12
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        orientation: 'horizontal',
        offsetY: -10,
        background: {
          borderWidth: 0
        },
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          colors: ['#828282', '#FFA500', '#fed766', '#2ab7ca', '#fe4a49']
        }
      },
      fill: {
        colors: ['#FFD8DD']
      },
      stroke: {
        width: 1,
        curve: 'smooth',
        colors: ['#828282', '#FFA500', '#fed766', '#2ab7ca', '#fe4a49']
      },
      annotations: {
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            border: true,
            fontSize: '9px',
            colors: '#eeeeee'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#FFFFFF',
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        title: {
          style: {
            color: '#FFFFFF'
          }
        }
      },
      yaxis: {
        min: 0,
        labels: {
          style: {
            fontSize: '10px',
            colors: '#FFFFFF'
          }
        }
      }
    }
  }),
  watch: {
    'graphDAU.month' () {
      this.LoadGraphDAU()
    },
    activePusat () {
      this.InitLoad()
    },
    graphAreaSelected () {
      this.LoadGraph()
    },
    dates: function (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            this.LoadStokistOmzet('?from=' + fromTo[0] + '&to=' + fromTo[1])
          } else {
            this.LoadStokistOmzet('?from=' + fromTo[1] + '&to=' + fromTo[0])
          }
        } else {
          this.LoadStokistOmzet()
        }
      } else {
        this.LoadStokistOmzet()
      }
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  created () {
    this.chartSeries = []
  },
  mounted () {
    this.InitLoad()
    this.LoadGraphDAU()
    this.$watch('$vuetify.theme.dark', (dark) => {
      this.BindColor(dark)
    })
  },
  methods: {
    async InitLoad () {
      // this.chartOptions.fill.gradient.gradientToColors = this.$vuetify.theme.dark ? ['#C6893F'] : ['#C6893F']

      // this.chartLoaded = false

      await this.$store.dispatch('GRAPH_OVERVIEW_GET', (parseInt(this.activePusat) ? '?id=' + this.activePusat : '')).then((res) => {
        if (res.status) {
          this.GraphOverview.total_member = res.data.data.total_member || ''
          this.GraphOverview.total_sell = res.data.data.total_sell || 0
          this.GraphOverview.total_buy = res.data.data.total_buy || 0
        }
        this.$store.dispatch('MEDIA_FORUM_GET')
      })
      this.chartSeries = []
      await this.$store.dispatch('GRAPH_GET', '?q=' + this.$store.state.user.id + '&month=' + this.GraphMonth + (parseInt(this.activePusat) ? '&id=' + this.activePusat : '')).then((res) => {
        if (res.status) {
          const newSeriesSell = {
            type: 'area',
            name: 'Omzet',
            // color: '#FF0000',
            data: this.$LastMonth(this.GraphMonth).map(r => {
              const findMonth = res.data.data.sell.find(rr => {
                return rr.dt === r
              })
              return findMonth ? parseInt(findMonth.omzet) || 0 : 0
            })
          }
          const newSeriesBuy = {
            type: 'area',
            name: 'Hpp',
            // color: '#FF0000',
            data: this.$LastMonth(this.GraphMonth).map(r => {
              const findMonth = res.data.data.buy.find(rr => {
                return rr.dt === r
              })
              return findMonth ? parseInt(findMonth.omzet) || 0 : 0
            })
          }
          this.bindToCart(newSeriesSell, res.data.data.name)
          if (this.$store.state.user.role !== 'pusat') {
            this.bindToCart(newSeriesBuy, res.data.data.role + ': ' + res.data.data.name)
          }
          setTimeout(() => {
            this.chartLoaded = true
          }, 200)
        }
      })
      this.LoadGraph()
      // this.loadReward()
      this.LoadStokistOmzet()
      await this.BindColor(this.$vuetify.theme.dark)
    },
    // MEMBER_STOKIST_GET () {
    //   this.$store.dispatch('MEMBER_STOKIST_GET').then((res) => {
    //     if (res.status) {
    //       this.stokistData = res.data.data || []
    //     }
    //   })
    // },
    async LoadGraphDAU () {
      this.chartLoadedDAU = false
      this.chartSeriesDAU = []
      this.chartOptionsDAU.xaxis.categories = []
      await this.$store.dispatch('GRAPH_DAU_GET', ('?month=' + (this.graphDAU.month || 12)))
        .then((res) => {
          if (res.status) {
            this.chartSeriesDAU.push({
              type: 'line',
              name: 'Customer',
              data: this.$LastMonth(this.graphDAU.month || 12).map(r => {
                const findMonth = res.data.data.customer.find(rr => {
                  return rr.dt === r
                })
                return findMonth ? parseInt(findMonth.total) || 0 : 0
              })
            })
            this.chartSeriesDAU.push({
              type: 'line',
              name: 'Reseller',
              data: this.$LastMonth(this.graphDAU.month || 12).map(r => {
                const findMonth = res.data.data.reseller.find(rr => {
                  return rr.dt === r
                })
                return findMonth ? parseInt(findMonth.total) || 0 : 0
              })
            })
            this.chartSeriesDAU.push({
              type: 'line',
              name: 'Total',
              data: this.$LastMonth(this.graphDAU.month || 12).map(r => {
                const findMonthCustomer = res.data.data.customer.find(rr => {
                  return rr.dt === r
                })
                const findMonthReseller = res.data.data.reseller.find(rr => {
                  return rr.dt === r
                })
                const tcustomer = (findMonthCustomer ? (parseInt(findMonthCustomer.total) || 0) : 0)
                const treseller = (findMonthReseller ? (parseInt(findMonthReseller.total) || 0) : 0)
                return tcustomer + treseller
              })
            })
            this.chartOptionsDAU.xaxis.categories = this.$LastMonth(this.graphDAU.month || 12)
          }
        })
      this.chartLoadedDAU = true
    },
    async BindColor (dark) {
      this.chartLoaded = false
      this.chartOptions.grid.borderColor = dark ? '#777777' : '#f0f0f0'
      this.chartOptions.yaxis.labels.style.colors = dark ? '#eee' : '#777'
      this.chartOptions.xaxis.labels.style.colors = dark ? '#eee' : '#777'
      this.chartOptions.title.style.color = dark ? '#BBB' : '#333'
      // this.chartOptions.fill.gradient.gradientToColors = dark ? ['#FFD8DD'] : ['#C6893F']
      setTimeout(() => {
        this.chartLoaded = true
      }, 50)

      this.chartLoadedArea = false
      this.chartOptionsArea.title.style.color = dark ? '#aaa' : '#333'
      this.chartOptionsArea.grid.borderColor = dark ? '#777777' : '#f0f0f0'
      this.chartOptionsArea.yaxis.labels.style.colors = dark ? '#eee' : '#777'
      this.chartOptionsArea.xaxis.labels.style.colors = dark ? '#eee' : '#777'
      this.chartOptionsArea.title.style.color = dark ? '#BBB' : '#333'
      // this.chartOptions.fill.gradient.gradientToColors = dark ? ['#FFD8DD'] : ['#C6893F']
      setTimeout(() => {
        this.chartLoadedArea = true
      }, 50)

      this.chartLoadedDAU = false
      this.chartOptionsDAU.grid.borderColor = dark ? '#777777' : '#f0f0f0'
      this.chartOptionsDAU.yaxis.labels.style.colors = dark ? '#eee' : '#777'
      this.chartOptionsDAU.xaxis.labels.style.colors = dark ? '#eee' : '#777'
      this.chartOptionsDAU.title.style.color = dark ? '#BBB' : '#333'
      // this.chartOptions.fill.gradient.gradientToColors = dark ? ['#FFD8DD'] : ['#C6893F']
      setTimeout(() => {
        this.chartLoadedDAU = true
      }, 50)
    },
    LoadStokistOmzet (q) {
      this.stokistData = []
      this.$store.dispatch('STOKIST_GET', (q || ''))
        .then((res) => {
          if (res.status) {
            this.stokistData = res.data.data || []
          }
        })
    },
    countPoint (poin) {
      const percent = parseInt(this.$store.state.poin_limit) > parseInt(poin) ? 100 : (parseInt(this.$store.state.poin_limit) * 100) / parseInt(poin)
      return percent
    },
    loadReward () {
      const role = this.$store.state.user.role === 'agen' || this.$store.state.user.role === 'distributor' ? this.$store.state.user.role : ''
      if (role) {
        this.$store.dispatch('REWARD_GET', '?role=' + (role || '')).then((res) => {
          this.graph.rewards = res.data.data.data || []
        })
      }
    },
    LoadGraph () {
      const type = this.graphAreaSelected || 'city'
      this.chartOptionsArea.title.text = 'Most Active ' + (type || 'city')
      this.chartLoadedArea = false
      this.chartSeriesArea = []
      this.$store.dispatch('GRAPH_AREA', '?type=' + (type || 'city')).then((res) => {
        if (res.status) {
          this.chartOptionsArea.xaxis.categories = []
          this.graph.area = res.data.data || []
          this.chartSeriesArea.push({
            type: 'bar',
            name: type || 'city',
            data: []
          })
          this.graph.area.map((r) => {
            if (type === 'province') {
              this.chartOptionsArea.xaxis.categories.push(r.province_name)
            } else {
              this.chartOptionsArea.xaxis.categories.push(r.city_name + ' (' + r.city_type + ')')
            }
            this.chartSeriesArea[0].data.push(parseInt(r.total))
          })
          // console.log(this.chartOptionsArea.xaxis.categories)
        }
        // console.log(this.chartSeriesArea)
        this.chartLoadedArea = true
      })
      // this.$store.dispatch('GRAPH_PRODUCT_BEST').then((res) => {
      //   if (res.status) {
      //     this.graph.productBest = res.data.data || []
      //   }
      // })
      this.$store.dispatch('GRAPH_BUYER', '?role=customer' + (this.activePusat ? ('&user=' + this.activePusat) : '')).then((res) => {
        if (res.status) {
          this.graph.buyer.customer = res.data.data || []
        }
      })
      this.$store.dispatch('GRAPH_BUYER', '?role=reseller' + (this.activePusat ? ('&user=' + this.activePusat) : '')).then((res) => {
        if (res.status) {
          this.graph.sales.reseller = res.data.data || []
        }
      })
      this.$store.dispatch('GRAPH_PRODUCT_SELLING', (this.activePusat ? ('?user=' + this.activePusat) : '')).then((res) => {
        if (res.status) {
          this.graph.product = res.data.data || []
        }
      })
      // this.$store.dispatch('GRAPH_SALES', '?role=distributor').then((res) => {
      //   if (res.status) {
      //     this.graph.sales.distributor = res.data.data || []
      //   }
      // })
      // this.$store.dispatch('GRAPH_SALES', '?role=agen').then((res) => {
      //   if (res.status) {
      //     this.graph.sales.agen = res.data.data || []
      //   }
      // })
    },
    bindToCart (data, title) {
      if (!this.chartOptions.xaxis.categories.length) {
        this.chartOptions.xaxis.categories = this.$LastMonth(this.GraphMonth)
      }
      this.chartOptions.title.text = 'Info Graphic - Omzet'
      this.chartSeries.push(data)
    },
    ParseTotalMember (members, role, t) {
      if (members) {
        const m = members.split(',')
        let distributor = 0
        let agen = 0
        let reseller = 0
        let customer = 0
        let total = 0
        for (let i = 0; i < m.length; i++) {
          const mm = m[i] ? m[i].split(':') : ['', 0]
          if (mm[0] === 'distributor') {
            distributor = parseInt(mm[1])
          } else if (mm[0] === 'agen') {
            agen = parseInt(mm[1])
          } else if (mm[0] === 'reseller') {
            reseller = parseInt(mm[1])
          } else if (mm[0] === 'customer') {
            customer = parseInt(mm[1])
          }
          total += parseInt(mm[1])
        }
        if (t) {
          return total.toLocaleString()
        } else if (role === 'pusat') {
          return 'D: ' + distributor + ', A: ' + agen + ', R: ' + reseller + ', C: ' + customer
        } else if (role === 'distributor') {
          return 'A: ' + agen + ', R: ' + reseller + ', C: ' + customer
        } else if (role === 'agen') {
          return 'R: ' + reseller + ', C: ' + customer
        } else if (role === 'reseller') {
          return 'C: ' + customer
        } else {
          return 0
        }
        // return {
        //   d: distributor || 0,
        //   a: agen || 0,
        //   r: reseller || 0,
        //   c: customer || 0,
        //   t: total || 0
        // }
      }
      return 0
    },
    c (c) {
      if (c === 0) {
        return 'green'
      } else if (c === 1) {
        return 'yellow darken-3'
      } else if (c === 2) {
        return 'red darken-1'
      } else if (c === 3) {
        return 'cyan darken-1'
      } else if (c === 4) {
        return 'blue darken-2'
      } else {
        return 'black'
      }
    },
    SplitMember (members) {
      // const role = this.$store.state.user.role
      if (members) {
        const m = members.split(',')
        // let distributor = 0
        // let agen = 0
        // let reseller = 0
        let customer = 0
        for (let i = 0; i < m.length; i++) {
          const mm = m[i] ? m[i].split(':') : ['', 0]
          // if (mm[0] === 'distributor') {
          //   distributor = parseInt(mm[1])
          // } else if (mm[0] === 'agen') {
          //   agen = parseInt(mm[1])
          // } else if (mm[0] === 'reseller') {
          //   reseller = parseInt(mm[1])
          // } else
          if (mm[0] === 'customer') {
            customer = parseInt(mm[1])
          }
        }
        //   if (role === 'pusat') {
        //     return [
        //       {
        //         name: 'Distributor',
        //         total: distributor || 0,
        //         color: 'red lighten-1'
        //       },
        //       {
        //         name: 'Agen',
        //         total: agen || 0,
        //         color: 'orange'
        //       },
        //       {
        //         name: 'Reseller',
        //         total: reseller || 0,
        //         color: 'purple'
        //       },
        //       {
        //         name: 'Customer',
        //         total: customer || 0,
        //         color: 'green'
        //       }
        //     ]
        //   } else if (role === 'distributor') {
        //     return [
        //       {
        //         name: 'Agen',
        //         total: agen || 0,
        //         color: 'orange'
        //       },
        //       {
        //         name: 'Reseller',
        //         total: reseller || 0,
        //         color: 'purple'
        //       },
        //       {
        //         name: 'Customer',
        //         total: customer || 0,
        //         color: 'green'
        //       }
        //     ]
        //   } else if (role === 'agen') {
        //     return [
        //       {
        //         name: 'Reseller',
        //         total: reseller || 0,
        //         color: 'purple'
        //       },
        //       {
        //         name: 'Customer',
        //         total: customer || 0,
        //         color: 'green'
        //       }
        //     ]
        //   } else if (role === 'reseller') {
        //     return [
        //       {
        //         name: 'Customer',
        //         total: customer || 0,
        //         color: 'green'
        //       }
        //     ]
        //   } else {
        //     return []
        //   }
        // }
        return [
          {
            name: 'Customer',
            total: customer || 0,
            color: 'green'
          }
        ]
      }
      //     return []
    }
  }
}
</script>

<style lang="scss">
.apexcharts-title-text {
  text-transform: unset;
}
.overflow-card {
  position: relative;
  >div {
    z-index: 2;
    position: relative;
  }
  .overflow-card-item {
    position: absolute;
    height: 100%;
    width: 200px;
    top: 0px;
    right: 0px;
    overflow: hidden;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      top: -50%;
      right: -50px;
      width: 100%;
      height: 200%;
      border-radius: 50%!important;
    }
    &.x1 {
      &::before {
        background: rgba(19, 84, 182, 0.2);
      }
    }
    &.x2 {
      &::before {
        background: rgba(43, 255, 0, 0.1);
      }
    }
  }
}
</style>
