import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import VueCoreVideoPlayer from 'vue-core-video-player'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueCoreVideoPlayer)

Vue.component('Map', () => import(/* webpackChunkName: "map" */ '@/components/Map.vue'))
Vue.component('Auth', () => import(/* webpackChunkName: "auth" */ '@/components/Auth.vue'))
Vue.component('Cart', () => import(/* webpackChunkName: "cart" */ '@/components/Cart.vue'))
Vue.component('Discount', () => import(/* webpackChunkName: "discount" */ '@/components/Discount.vue'))
Vue.component('PdfViewer', () => import(/* webpackChunkName: "pdf-viewer" */ '@/components/PdfViewer.vue'))
Vue.component('EbrightEditor', () => import(/* webpackChunkName: "ebright-editor" */ '@/components/EbrightEditor.vue'))
Vue.component('Currency', () => import(/* webpackChunkName: "v-currency" */ '@/components/Currency.vue'))
Vue.component('InvoiceView', () => import(/* webpackChunkName: "invoice-view" */ '@/components/InvoiceView.vue'))
Vue.component('InvoiceViewPurchase', () => import(/* webpackChunkName: "invoice-view-purchase" */ '@/components/InvoiceViewPurchase.vue'))
Vue.component('Popup', () => import(/* webpackChunkName: "popup" */ '@/components/Popup.vue'))
Vue.component('ProductSearch', () => import(/* webpackChunkName: "product-search" */ '@/components/ProductSearch.vue'))
Vue.component('StocksInventory', () => import(/* webpackChunkName: "stocks-inventory" */ '@/components/StocksInventory.vue'))
Vue.component('AcquisitionTools', () => import(/* webpackChunkName: "acquisition-tools" */ '@/components/AcquisitionTools.vue'))
Vue.component('SearchCustomer', () => import(/* webpackChunkName: "search-customer" */ '@/components/SearchCustomer.vue'))
Vue.component('REFID', () => import(/* webpackChunkName: "refid" */ '@/components/REFID.vue'))
Vue.component('DialogShopee', () => import(/* webpackChunkName: "dialog-shopee" */ '@/components/DialogShopee.vue'))
