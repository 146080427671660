import Vue from 'vue'
import Vuex from 'vuex'
import request from './api/request'
import emoticons from './emoticons'

Vue.use(Vuex)

const initialTrxSource = [
  { id: 'WA', name: 'Whatsapp' },
  { id: 'WEB', name: 'Web' },
  { id: 'APP', name: 'App' },
  { id: 'MIGRATION/OLD_CRM', name: 'Old CRM' },
  { id: 'GA', name: 'GA' },
  { id: 'DM', name: 'Ads & DM' },
  { id: 'RESELLER', name: 'Reseller' },
  { id: 'MULTIMEDIA', name: 'Multimedia' },
  { id: 'EVERMOST', name: 'Evermost' },
  { id: 'TIKTOK', name: 'Tiktok Shop' },
  { id: 'PR', name: 'PR' },
  { id: 'KASBON', name: 'KASBON' },
  { id: 'MPL', name: 'MPL ALL' },
  { id: 'MPL/SHOP', name: 'MPL Shopee' },
  { id: 'MPL/TOKO', name: 'MPL Tokopedia' },
  { id: 'MPL/BUKA', name: 'MPL Bukalapak' },
  { id: 'MPL/LAZA', name: 'MPL Lazada' },
  { id: 'MPL/BLIBLI', name: 'MPL Blibli' },
  { id: 'MPL/HALAL', name: 'MPL Halalpedia' },
  { id: 'MPL/OTHER', name: 'Other Marketplace' }
]

export default new Vuex.Store({
  state: {
    user: null,
    poin_limit: 0,
    cart: [],
    snackbarShow: false,
    snackbarText: '',
    snackbarCentered: false,
    screenWidth: 0,
    screenHeight: 0,
    mediaForumRead: 0,
    progress: 0,
    notifications: [],
    popup: {
      show: false,
      type: '',
      title: 'Info',
      text: '',
      value: '',
      confirmResult: false,
      bCancel: 'Cancel',
      bOk: 'Ok'
    },
    user_admin: [],
    stokist: [],
    trxSource: initialTrxSource,
    initialTrxSource,
    itemsCouriers: [
      { value: 'jne', name: 'JNE', show: true },
      { value: 'ninja', name: 'Ninja', show: true },
      { value: 'jnt', name: 'J&T', show: true },
      { value: 'pos', name: 'POS', show: true },
      { value: 'sicepat', name: 'SICEPAT', show: true },
      { value: 'ide', name: 'ID Express', show: true },
      { value: 'cod', name: 'COD / PICKUP', show: true }
    ],
    user_roles: [
      { name: 'Admin', value: ['transaction', 'product', 'user', 'account-setting', 'product-sell', 'product-package', 'product-checkout-discount', 'product-options', 'product-options-cart', 'product-options-merchandise', 'product-options-pricing', 'product-options-additional', 'product-options-detail', 'user-add'] },
      { name: 'Finance', value: ['transaction', 'product', 'user', 'finance', 'account-setting', 'transaction-hpp', 'transaction-margin', 'product-stockcheck-hpp', 'product-stockcheck-margin'] },
      { name: 'Logistik', value: ['transaction', 'product', 'user', 'packaging', 'transaction-purchase', 'transaction-stock-moving'] },
      { name: 'Sales', value: ['transaction', 'product', 'user', 'biolink', 'user-add-followup', 'user-acquisition-tools'] },
      { name: 'Marketing', value: ['transaction', 'product', 'user', 'user-acquisition-tools'] }
    ],
    vendor: {
      data: [],
      total: 0
    },
    dialogShopee: false
  },
  mutations: {
    DIALOG_SHOPEE (state, v) {
      state.dialogShopee = v || false
    },
    SET_VENDOR (state, v) {
      state.vendor.data = v.data || []
      state.vendor.total = parseInt(v.total) || 0
    },
    SET_POPUP_VALUE (state, v) {
      state.popup.value = v || ''
    },
    SET_USER_ADMIN (state, v) {
      state.user_admin = v || []
    },
    STOKIST_DATA (state, v) {
      state.stokist = v || []
    },
    SET_POPUP (state, v) {
      state.popup.confirmResult = v.confirmResult || false
      state.popup.bCancel = v.bCancel || 'Cancel'
      state.popup.bOk = v.bOk || 'Ok'
      state.popup.type = (v.type || '') // '' || confirm || confirm_value
      if (v.show) {
        state.popup.value = v.value || ''
        state.popup.title = v.title
        state.popup.text = v.text
      }
      state.popup.show = v.show
    },
    SET_NOTIFY (state, v) {
      const uid = parseInt(state.user ? state.user.id : 0)
      state.notifications = v.map((res) => {
        res.read = 0
        if (res.subscriber) {
          const r = new RegExp(':' + uid + ':', 'gi')
          if (res.subscriber.match(r)) {
            res.read = 1
          }
        }
        return res
      }) || []
    },
    PROGRESS (state, v) {
      state.progress = parseInt(v) || 0
    },
    SET_MEDIA_FORUM_READ (state, v) {
      state.mediaForumRead = parseInt(v) || 0
    },
    SET_USER (state, v) {
      state.user = v || null
    },
    CART_SET (state, v) {
      state.cart = v || []
    },
    POIN_LIMIT_SET (state, v) {
      state.poin_limit = parseInt(v) || 0
    },
    SNACKBAR (state, v) {
      state.snackbarShow = v.show
      state.snackbarText = v.text || ''
      state.snackbarCentered = v.center || false
    },
    RESIZE (state, v) {
      state.screenWidth = v.x
      state.screenHeight = v.y
    }
  },
  actions: {
    async POPUP ({ state, commit }, params) {
      const t = params.t
      commit('SET_POPUP', params)
      const popupResult = await new Promise((resolve) => {
        t.$watch('$store.state.popup.show', (result) => {
          if (!result) {
            resolve({
              result: state.popup.confirmResult,
              value: state.popup.value
            })
          }
        })
      })
      return popupResult
    },
    async ME ({ state, commit, dispatch }) {
      return await request.get('crm/me').then((res) => {
        if (res.status) {
          dispatch('POIN_LIMIT_GET')
          dispatch('NOTIFY_GET')
          dispatch('ADMIN_GET')
          commit('SET_USER', res.data.data)
          // if (navigator.geolocation) {
          //   navigator.geolocation.getCurrentPosition((position) => {
          //     dispatch('ME_UPDATE', {
          //       id: parseInt(res.data.data.id),
          //       lat: position.coords.latitude,
          //       lng: position.coords.longitude,
          //       once: true
          //     })
          //   })
          // } else {
          //   commit('SNACKBAR', { show: true, text: 'Please allow this app to access your location' })
          // }
        } else {
          commit('SET_USER', null)
        }
        return res
      }).catch(err => err)
    },
    async ME_UPDATE ({ commit, dispatch }, params) {
      return request.put('/crm/me', params)
        .then((res) => {
          if (res.status && !params.once) {
            dispatch('ME')
          }
          return res
        })
    },
    async SHOPEE_GET ({ commit, dispatch }, q) {
      return request.get('/marketplace/shopee-list' + (q || ''))
        .then(res => res)
    },
    async SHOPEE_ORDER ({ commit, dispatch }, q) {
      return request.get('/marketplace/shopee-order' + (q || ''))
        .then(res => res)
    },
    async SHOPEE_DETAIL_GET ({ commit, dispatch }, q) {
      return request.get('/marketplace/shopee-order-detail' + (q || ''))
        .then(res => res)
    },
    async ADMIN_GET (context) {
      return await request.get('crm/user-admin').then((res) => {
        if (res.status) {
          context.commit('SET_USER_ADMIN', res.data.data.map((r) => {
            if (r.roles) {
              try {
                r.roles = JSON.parse(r.roles)
              } catch {
                r.roles = []
              }
            } else {
              r.roles = []
            }

            r.value = r.id
            return r
          }))
        }
      }).catch(err => err)
    },
    async ADMIN_PROCESS (context, params) {
      return await request.post('crm/user-admin', params).then((res) => {
        context.dispatch('ADMIN_GET')
        return res
      }).catch(err => err)
    },
    async SEND_PASSWORD (context, email) {
      return await request.post('crm/pwd', { email: email }).then(res => res).catch(err => err)
    },
    async POIN_LIMIT_GET (context, user) {
      return await request.get('crm-poin/point/limit' + (user ? '?user=' + user : '')).then((res) => {
        if (user) {
          return res
        } else if (res.status) {
          context.commit('POIN_LIMIT_SET', parseInt(res.data.data) || 0)
        } else {
          context.commit('POIN_LIMIT_SET', 0)
        }
      }).catch(err => err)
    },
    async POIN_GET (context, query) {
      return await request.get('crm-poin/point' + (query || '')).then(res => res).catch(err => err)
    },
    async POIN_ADD (context, params) {
      return await request.post('crm-poin/point', params).then(res => res).catch(err => err)
    },
    async POIN_UPDATE (context, params) {
      return await request.put('crm-poin/point/' + params.id, params).then(res => res).catch(err => err)
    },
    async POIN_DELETE (context, pointID) {
      return await request.delete('crm-poin/point/' + pointID).then(res => res).catch(err => err)
    },
    async REWARD_GET_ONE (context, rewardID) {
      return await request.get('crm-poin/rewards/' + (rewardID || '')).then(res => res).catch(err => err)
    },
    async REWARD_GET (context, query) {
      return await request.get('crm-poin/rewards' + (query || '')).then(res => res).catch(err => err)
    },
    async REWARD_ADD (context, params) {
      return await request.post('crm-poin/rewards', params).then(res => res).catch(err => err)
    },
    async REWARD_UPDATE (context, params) {
      return await request.put('crm-poin/rewards/' + params.id, params).then(res => res).catch(err => err)
    },
    async REWARD_DELETE (context, params) {
      return await request.delete('crm-poin/rewards/' + params.id, params).then(res => res).catch(err => err)
    },
    async MAP_GET (context, uid) {
      return await request.get('crm/map/' + uid).then(res => res).catch(err => err)
    },
    async SHOPEE_TRX_PROCESS (context, params) {
      return await request.post('crm-trx/shopee-trx', params).then(res => res).catch(err => err)
    },
    async LAST_REFID_GET (context, params) {
      return await request.get('crm-trx/last-refid' + (params || '')).then(res => res).catch(err => err)
    },
    async PURCHASE_GET (context, trx) {
      return await request.get('crm-trx/trx-purchase?trx=' + trx).then(res => res).catch(err => err)
    },
    async PURCHASE_PROCESS (context, params) {
      return await request.post('crm-trx/trx-purchase', params).then(res => res).catch(err => err)
    },
    async PURCHASE_ITEM_PROCESS (context, params) {
      return await request.post('crm-trx/trx-purchase/item', params).then(res => res).catch(err => err)
    },
    async PURCHASE_DELETE (context, id) {
      return await request.delete('crm-trx/trx-purchase/' + id).then(res => res).catch(err => err)
    },
    async PURCHASE_ITEM_DELETE (context, id) {
      return await request.delete('crm-trx/trx-purchase/item/' + id).then(res => res).catch(err => err)
    },
    async GRAPH_OVERVIEW_GET (context, q) {
      return await request.get('crm/graph' + (q || '')).then(res => res).catch(err => err)
    },
    async GRAPH_DAU_GET (context, q) {
      return await request.get('crm/graph-dau' + (q || '')).then(res => res).catch(err => err)
    },
    async GRAPH_AREA (context, q) {
      return await request.get('crm/graph-area' + (q || '')).then(res => res).catch(err => err)
    },
    async GRAPH_PRODUCT_BEST (context, q) {
      return await request.get('crm/graph-product-best' + (q || '')).then(res => res).catch(err => err)
    },
    async GRAPH_PRODUCT_SELLING (context, q) {
      return await request.get('crm/graph-product-selling' + (q || '')).then(res => res).catch(err => err)
    },
    async GRAPH_SALES (context, q) {
      return await request.get('crm/graph-sales' + (q || '')).then(res => res).catch(err => err)
    },
    async GRAPH_BUYER (context, q) {
      return await request.get('crm/graph-buyer' + (q || '')).then(res => res).catch(err => err)
    },
    async GRAPH_GET (context, q) {
      return await request.get('crm/graph/revenue' + (q || '')).then(res => res).catch(err => err)
    },
    async BIOLINK_SETTING_GET (context) {
      return await request.get('crm/biolink-setting').then(res => res).catch(err => err)
    },
    async BIOLINK_SETTING_PROCESS (context, params) {
      return await request.post('crm/biolink-setting', params).then(res => res).catch(err => err)
    },
    async BIOLINK_GET (context, q) {
      return await request.get('crm/biolink' + (q || '')).then(res => res).catch(err => err)
    },
    async BIOLINK_PROCESS (context, params) {
      return await request.post('crm/biolink', params).then(res => res).catch(err => err)
    },
    async BIOLINK_DELETE (context, bid) {
      return await request.delete('crm/biolink/' + (bid || 0)).then(res => res).catch(err => err)
    },
    async BALANCE (context, user) {
      return await request.get('crm/balance?user=' + (user || '')).then(res => res).catch(err => err)
    },
    async BALANCE_GET (context, params) {
      return await request.get('crm/balance/' + params.trx + '?user=' + (params.user || '') + '&inout=' + (params.inout || '') + '&page=' + (params.page || '1') + '&limit=' + (params.limit || '')).then(res => res).catch(err => err)
    },
    async BALANCE_PROCESS (context, params) {
      return await request.post('crm/balance', params).then(res => res).catch(err => err)
    },
    async BALANCE_DELETE (context, bid) {
      return await request.delete('crm/balance/' + (bid || 0)).then(res => res).catch(err => err)
    },
    async STOKIST_GET (context, q) {
      return await request.get('crm/stokist' + (q || '')).then((res) => {
        if (res.status) {
          context.commit('STOKIST_DATA', res.data.data)
        }
        return res
      }).catch(err => err)
    },
    async CREATE_INVOICE (context, params) {
      let MASTER_REFID = 'PROD/'
      if (process.env.NODE_ENV === 'development') {
        MASTER_REFID = 'DEV/'
      }
      params.externalID = MASTER_REFID + params.externalID
      return await request.post('crm-trx/create-invoice', params).then(res => res).catch(err => err)
    },
    async PRODUCT_STOCK_HISTORY (context, param) {
      return await request.get('crm-trx/product-stock-history/' + (param.pid || 0) + (param.q || '')).then(res => res).catch(err => err)
    },
    async PRODUCT_STOCKIST_GET (context, pid) {
      return await request.get('crm/stokist-product/' + (pid || 0)).then(res => res).catch(err => err)
    },
    async EBRIGHT_POIN (context, params) {
      return await request.post('crm/member-poin/' + (params.uid || ''), params).then(res => res).catch(err => err)
    },
    async USER_FOLLOWUP_GET (context, param) {
      return await request.get('crm/user-follow-up/' + (param.cid || 0) + (param.q || '')).then(res => res).catch(err => err)
    },
    async USER_FOLLOWUP_PROCESS (context, params) {
      return await request.post('crm/user-follow-up/' + (params.customer_id || 0), params).then(res => res).catch(err => err)
    },
    async USER_FOLLOWUP_DELETE (context, id) {
      return await request.delete('crm/user-follow-up/' + (id || 0)).then(res => res).catch(err => err)
    },
    async MEMBER_STOKIST_GET (context, q) {
      return await request.get('crm/member-me?stokist=true').then(res => res).catch(err => err)
    },
    async MEMBER_ME_GET (context, q) {
      return await request.get('crm/member-me' + (q || '')).then(res => res).catch(err => err)
    },
    async MEMBER_GET_ONE (context, user) {
      return await request.get('crm/member/' + (user || '')).then(res => res).catch(err => err)
    },
    async MEMBER_GET (context, q) {
      return await request.get('crm/member' + (q || '')).then(res => res).catch(err => err)
    },
    async MEMBER_ADD (context, params) {
      return await request.post('crm/member', params).then(res => res).catch(err => err)
    },
    async MEMBER_UPDATE (context, params) {
      if (parseInt(params.id) === parseInt(context.state.user.id)) {
        return await context.dispatch('ME_UPDATE', params)
      } else {
        return await request.put('crm/member/' + params.id, params).then(res => res).catch(err => err)
      }
    },
    async MEMBER_DELETE (context, params) {
      return await request.delete('crm/member/' + params.id).then(res => res).catch(err => err)
    },
    async PRODUCT_CATEGORIES_GET (context) {
      return await request.get('product/categories').then(res => res).catch(err => err)
    },
    async PRODUCT_CATEGORY_ADD (context, params) {
      return await request.post('product/category', params).then(res => res).catch(err => err)
    },
    async PRODUCT_CATEGORY_UPDATE (context, params) {
      return await request.put('product/category/' + params.id, params).then(res => res).catch(err => err)
    },
    async PRODUCT_PACKAGE_GET (context, pid) {
      return await request.get('crm/product-package/' + (pid || 0)).then(res => res).catch(err => err)
    },
    async PRODUCT_PACKAGE_REMOVE (context, packageID) {
      return await request.delete('crm/product-package/package/' + (packageID || 0)).then(res => res).catch(err => err)
    },
    async REMOVE_PACKAGE_ITEM (context, params) {
      return await request.delete('crm/product-package/package-item/' + (params.trx || 0) + '/' + (params.itemid || 0)).then(res => res).catch(err => err)
    },
    async PRODUCT_GET (context, q) {
      return await request.get('crm/product' + (q || '')).then(res => res).catch(err => err)
    },
    async PRODUCT_ADD (context, params) {
      return await request.post('crm/product', params).then(res => res).catch(err => err)
    },
    async PRODUCT_UPDATE (context, params) {
      return await request.put('crm/product/' + params.id, params).then(res => res).catch(err => err)
    },
    async PRICING_ADD (context, params) {
      return await request.post('crm/pricing', params).then(res => res).catch(err => err)
    },
    async PRICING_UPDATE (context, params) {
      return await request.put('crm/pricing/' + params.id, params).then(res => res).catch(err => err)
    },
    async SHIPPING_GET (context, q) {
      return await request.get('shipping' + (q || '')).then(res => res).catch(err => err)
    },
    async SHIPPING_DISTRICT_GET (context, q) {
      return await request.get('shipping/kecamatan' + (q || '')).then(res => res).catch(err => err)
    },
    async SHIPPING_CHECK (context, params) {
      return await request.post('shipping', params).then(res => res).catch(err => err)
    },
    async ORIGIN_GET (context) {
      const q = context.state.user.role === 'pusat' ? '?all=true' : ''
      return await request.get('shipping/origin' + q).then(res => res).catch(err => err)
    },
    async ORIGIN_ADD (context, params) {
      return await request.post('shipping/origin', params).then(res => res).catch(err => err)
    },
    async ORIGIN_UPDATE (context, params) {
      return await request.put('shipping/origin', params).then(res => res).catch(err => err)
    },
    async ORIGIN_DELETE (context, params) {
      return await request.delete('shipping/origin/' + params.id).then(res => res).catch(err => err)
    },
    async GLOBAL_PRICING_GET (context) {
      return await request.get('crm/global-price').then(res => res).catch(err => err)
    },
    async GLOBAL_PRICING_SELLER_GET (context, buyerID) {
      return await request.get('crm/global-price-seller/' + buyerID).then(res => res).catch(err => err)
    },
    async GLOBAL_PRICING_ADD (context, params) {
      return await request.post('crm/global-price', params).then(res => res).catch(err => err)
    },
    async GLOBAL_PRICING_ADD_BULK (context, params) {
      return await request.post('crm/global-price-bulk', params).then(res => res).catch(err => err)
    },
    async GLOBAL_PRICING_UPDATE (context, params) {
      return await request.put('crm/global-price/' + params.id, params).then(res => res).catch(err => err)
    },
    async GLOBAL_PRICING_DELETE (context, cid) {
      return await request.delete('crm/global-price/' + cid).then(res => res).catch(err => err)
    },
    async VENDOR_GET (context, query) {
      return await request.get('crm/vendor' + (query || '')).then((res) => {
        if (res.status) {
          context.commit('SET_VENDOR', res.data.data || {
            data: [],
            total: 0
          })
        } else {
          context.commit('SET_VENDOR', {
            data: [],
            total: 0
          })
        }
      }).catch(err => err)
    },
    async VENDOR_PROCESS (context, params) {
      return await request.post('crm/vendor', params).then(res => res).catch(err => err)
    },
    async CART_TRX_GET (context, trxid) {
      return await request.get('crm-trx/cart-trx/' + trxid).then(res => res).catch(err => err)
    },
    async CART_GET (context, query) {
      return await request.get('crm/cart' + (query || '')).then((res) => {
        if (res.status) {
          context.commit('CART_SET', res.data.data || [])
        } else {
          context.commit('CART_SET', [])
        }
      }).catch(err => err)
    },
    async CART_ADD (context, params) {
      return await request.post('crm/cart', params).then(res => res).catch(err => err)
    },
    async CART_UPDATE (context, params) {
      return await request.post('crm/cart-update', params).then(res => res).catch(err => err)
    },
    async CART_DELETE (context, cid) {
      return await request.delete('crm/cart/' + cid).then(res => res).catch(err => err)
    },
    async STOCKS_GET (context, q) {
      return await request.get('crm-trx/stock' + (q || '')).then(res => res).catch(err => err)
    },
    async BANK_GET (context, q) {
      return await request.get('crm/bank' + (q || '')).then(res => res).catch(err => err)
    },
    async BANK_SELLER_GET (context, uid) {
      return await request.get('crm/bank-seller?uid=' + uid).then(res => res).catch(err => err)
    },
    async BANK_ADD (context, params) {
      return await request.post('crm/bank', params).then(res => res).catch(err => err)
    },
    async BANK_UPDATE (context, params) {
      return await request.put('crm/bank/' + params.id, params).then(res => res).catch(err => err)
    },
    async BANK_DELETE (context, params) {
      return await request.delete('crm/bank/' + params.id).then(res => res).catch(err => err)
    },
    async AREA_MPL_GET (context, params) {
      // http://localhost:3031/api/crm/area-postal-code/Kota/722?city_name=pontianak&kecamatan_name=pontianak%20barat
      return await request.get('http://localhost:3031/api/crm/area-postal-code/' + (params.type || 'Kota') + '/' + (params.postal_code || '0') + (params.q || '')).then(res => res).catch(err => err)
    },
    async AREA_GET (context, q) {
      return await request.get('shipping/area' + (q || '')).then(res => res).catch(err => err)
    },
    async ADDRESS_GET (context, uid) {
      return await request.get('crm/address?uid=' + uid).then(res => res).catch(err => err)
    },
    async ADDRESS_ADD (context, params) {
      return await request.post('crm/address', params).then(res => res).catch(err => err)
    },
    async ADDRESS_UPDATE (context, params) {
      return await request.put('crm/address/' + params.id, params).then(res => res).catch(err => err)
    },
    async ADDRESS_DELETE (context, params) {
      return await request.delete('crm/address/' + params.id).then(res => res).catch(err => err)
    },
    async ACQUISITION_GRAPH_GET (context, q) {
      return await request.get('crm/acquisition/graph' + (q || '')).then(res => res).catch(err => err)
    },
    async ACQUISITION_GRAPH_PROCESS (context, params) {
      return await request.post('crm/acquisition', params).then(res => res).catch(err => err)
    },
    async ACQUISITION_GRAPH_DELETE (context, tid) {
      return await request.delete('crm/acquisition/' + (tid || 0)).then(res => res).catch(err => err)
    },
    async TRX_GET (context, q) {
      return await request.get('crm-trx' + (q || '')).then(res => res).catch(err => err)
    },
    async TRX_GET_ONE_DETAIL (context, trxid) {
      return await request.get('crm-trx/' + (trxid || 0)).then(res => res).catch(err => err)
    },
    async TRX_ADD (context, params) {
      return await request.post('crm-trx', params).then(res => res).catch(err => err)
    },
    async TRX_UPDATE (context, params) {
      return await request.put('crm-trx/' + params.id, params).then(res => res).catch(err => err)
    },
    async TRX_INVOICE_UPDATE (context, params) {
      return await request.put('crm-trx/invoice/' + params.trx.id, params).then(res => res).catch(err => err)
    },
    async FINANCE_GET (context, params) {
      return await request.get('crm/finance/' + params.uid + (params.q || '')).then(res => res).catch(err => err)
    },
    async FINANCE_ADD (context, params) {
      return await request.post('crm/finance', params).then(res => res).catch(err => err)
    },
    async FINANCE_UPDATE (context, params) {
      return await request.put('crm/finance/' + params.id, params).then(res => res).catch(err => err)
    },
    async PACKAGING_HISTORY_GET (context, q) {
      return await request.get('crm/packaging-history' + (q || '')).then(res => res).catch(err => err)
    },
    async PACKAGING_HISTORY_PROCESS (context, params) {
      return await request.post('crm/packaging-history', params).then(res => res).catch(err => err)
    },
    async PACKAGING_HISTORY_ITEM_DELETE (context, itemid) {
      return await request.delete('crm/packaging-history-item/' + itemid).then(res => res).catch(err => err)
    },
    async PACKAGING_GET (context, q) {
      return await request.get('crm/packaging' + (q || '')).then(res => res).catch(err => err)
    },
    async PACKAGING_PROCESS (context, params) {
      return await request.post('crm/packaging', params).then(res => res).catch(err => err)
    },
    async FINANCE_DELETE (context, params) {
      return await request.delete('crm/finance/' + params.id + '/' + params.uid).then(res => res).catch(err => err)
    },
    async TRX_SYNC (context, params) {
      return await request.post('crm-trx/sync', params).then(res => res).catch(err => err)
    },
    async CHECKOUT (context, params) {
      // console.log('CHECKOUT params', params)
      return await request.post('crm-trx/checkout', params).then(res => res).catch(err => err)
    },
    async MEDIA_FORUM_GET (context, q) {
      return await request.get('crm/media' + (q || '')).then((res) => {
        if (res.status) {
          context.commit('SET_MEDIA_FORUM_READ', parseInt(res.data.data.unread) || 0)
        }
        return res
      }).catch(err => err)
    },
    async MEDIA_LEARNING_GET (context, q) {
      return await request.get('crm/media/learning' + (q || '')).then(res => res).catch(err => err)
    },
    async MEDIA_ADD (context, params) {
      return await request.post('crm/media', params).then(res => res).catch(err => err)
    },
    async MEDIA_UPDATE (context, params) {
      return await request.put('crm/media/' + params.id, params).then(res => res).catch(err => err)
    },
    async MEDIA_REMOVE (context, params) {
      return await request.delete('crm/media/' + params.id).then(res => res).catch(err => err)
    },
    async MEDIA_READ (context, params) {
      params.content_type = 'crm_media'
      return await request.post('crm/notify', params).then(res => res).catch(err => err)
    },
    async MEDIA_COMMENT_GET (context, params) {
      return await request.get('crm/media-comment/' + params.media + (params.q || '')).then(res => res).catch(err => err)
    },
    async MEDIA_COMMENT_ADD (context, params) {
      return await request.post('crm/media-comment', params).then(res => res).catch(err => err)
    },
    async MEDIA_COMMENT_UPDATE (context, params) {
      return await request.put('crm/media-comment/' + params.id, params).then(res => res).catch(err => err)
    },
    async MEDIA_COMMENT_REMOVE (context, params) {
      return await request.delete('crm/media-comment/' + params.id).then(res => res).catch(err => err)
    },
    async MEDIA_COMMENT_READ (context, params) {
      params.content_type = 'crm_media_comment'
      return await request.post('crm/notify', params).then(res => res).catch(err => err)
    },
    async NOTIFY_GET (context, q) {
      return await request.get('crm/notify' + (q || '')).then((res) => {
        if (res.status) {
          context.commit('SET_NOTIFY', res.data.data)
        }
        return res
      }).catch(err => err)
    },
    async NOTIFY_READ (context, params) {
      params.subscriber = (params.subscriber || '') + ':' + context.state.user.id + ':'
      return await request.put('crm/notify/' + params.id, params).then((res) => {
        context.dispatch('NOTIFY_GET')
        return res
      }).catch(err => err)
    },
    async LOGIN (context, params) {
      return request.post('/crm/login', params)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              localStorage.setItem('t', res.data.data.access_token)
              context.dispatch('ME')
            }
          }
          return res
        })
        .catch(e => null)
    },
    async UPLOAD_FILE (context, params) {
      context.commit('PROGRESS', 0)
      const config = {
        onUploadProgress: function (progressEvent) {
          context.commit('PROGRESS', Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
      }
      return await request.post('upload', params, config).then((res) => res).catch(() => null)
    },
    async GET_FILE (context, params) {
      return await request.post('crm/files', params, {
        responseType: 'arraybuffer'
      }).then((res) => res)
    },
    async DO_LOGOUT ({ commit }) {
      localStorage.removeItem('t')
      commit('SET_USER', null)
      location.reload()
    }
  },
  modules: {
    emoticons
  }
})
