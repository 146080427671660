/* eslint-disable */
const state = {
  emoticons: [
    [ ':)', '🙂' ],
    [ ':D', '😀' ],
    [ 'XD', '😂' ],
    [ ':p', '😋' ],
    [ ':(', '😢' ],
    [ ':|', '😐' ],
    [ 'B)', '😎' ],
    [ '-_-', '😑' ],
    [ ':*', '😘' ],
    [ ';)', '😉' ],

    [ '&#128578;', '🙂' ],
    [ '&#128512;', '😀' ],
    [ '&#128513;', '😁' ],
    [ '&#128514;', '😂' ],
    [ '&#128515;', '😃' ],
    [ '&#128516;', '😄' ],
    [ '&#128517;', '😅' ],
    [ '&#128518;', '😆' ],
    [ '&#128519;', '😇' ],
    [ '&#128521;', '😉' ],
    [ '&#128522;', '😊' ],
    [ '&#128523;', '😋' ],
    [ '&#128524;', '😌' ],
    [ '&#128525;', '😍' ],
    [ '&#128526;', '😎' ],
    [ '&#128527;', '😏' ],
    [ '&#128528;', '😐' ],
    [ '&#128529;', '😑' ],
    [ '&#128530;', '😒' ],
    [ '&#128531;', '😓' ],
    [ '&#128532;', '😔' ],
    [ '&#128533;', '😕' ],
    [ '&#128534;', '😖' ],
    [ '&#128535;', '😗' ],
    [ '&#128536;', '😘' ],
    [ '&#128537;', '😙' ],
    [ '&#128538;', '😚' ],
    [ '&#128539;', '😛' ],
    [ '&#128540;', '😜' ],
    [ '&#128541;', '😝' ],
    [ '&#128542;', '😞' ],
    [ '&#128543;', '😟' ],
    [ '&#128544;', '😠' ],
    [ '&#128545;', '😡' ],
    [ '&#128546;', '😢' ],
    [ '&#128547;', '😣' ],
    [ '&#128548;', '😤' ],
    [ '&#128549;', '😥' ],
    [ '&#128550;', '😦' ],
    [ '&#128551;', '😧' ],
    [ '&#128552;', '😨' ],
    [ '&#128553;', '😩' ],
    [ '&#128554;', '😪' ],
    [ '&#128555;', '😫' ],
    [ '&#128556;', '😬' ],
    [ '&#128557;', '😭' ],
    [ '&#128558;', '😮' ],
    [ '&#128559;', '😯' ],
    [ '&#128560;', '😰' ],
    [ '&#128561;', '😱' ],
    [ '&#128562;', '😲' ],
    [ '&#128563;', '😳' ],
    [ '&#128564;', '😴' ],
    [ '&#128565;', '😵' ],
    [ '&#128566;', '😶' ],
    [ '&#128567;', '😷' ],
    [ '&#128577;', '🙁' ],
    [ '&#128579;', '🙃' ],
    [ '&#128580;', '🙄' ],
    [ '&#128296;', '🤐' ],
    [ '&#128297;', '🤑' ],
    [ '&#128298;', '🤒' ],
    [ '&#128299;', '🤓' ],
    [ '&#128300;', '🤔' ],
    [ '&#128301;', '🤕' ],
    [ '&#128312;', '🤠' ],
    [ '&#128314;', '🤢' ],
    [ '&#128315;', '🤣' ],
    [ '&#128316;', '🤤' ],
    [ '&#128317;', '🤥' ],
    [ '&#128319;', '🤧' ],
    [ '&#128320;', '🤨' ],
    [ '&#128321;', '🤩' ],
    [ '&#128322;', '🤪' ],
    [ '&#128323;', '🤫' ],
    [ '&#128324;', '🤬' ],
    [ '&#128325;', '🤭' ],
    [ '&#128326;', '🤮' ],
    [ '&#128488;', '🧐' ]
  ]
}

export default {
  namespaced: true,
  state
}
