import './plugins/axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/components'
import '@/assets/sass/main.scss'

import Invoice from 'vue-invoice'
Vue.use(Invoice, { store })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
